/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Table } from "../../Checkout/OderDetails/style";
//import {ProductElement} from './style';
import { API, images , STATIC} from "../../../constants/env";

import moment from "moment";
import "moment/locale/fr";
import { useParams } from "react-router-dom";
import { CheckoutText, MyAccountText } from "../../../constants/texts";
import { getToken } from "../../../services/LoadToken";
const OrderDetail = (props) => {
  const [order, setOrder] = useState([]);
  const [contentProducts, setContentProducts] = useState([]);
  const [storeInformation, setStoreInformation] = useState({
    adress: "124 Avenue Parmentier, 75011 Paris, France",
    email: "olivier@klikx.lol",
    formatedAdress: "124 Avenue Parmentier, 75011 Paris, France",
    logo: "/statics/product-1597486560136.png",
    name: "Klikx",
    phone: "516467464",
  });
  let { id } = useParams();
  React.useEffect(() => {
    const fetchOrder = async () => {
      const token = getToken();
      await fetch(API.me.getOrderId(id), {
        method: "GET",
        headers: {
          "x-auth-token": token,
          "x-site-id": sessionStorage.getItem('currentSite') ? JSON.parse(sessionStorage.getItem('currentSite'))._id : '',
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.error) {
          } else {
            setOrder(response);
            setContentProducts(response.content);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    };
    fetchOrder();
  }, [props.user]);
  React.useEffect(() => {
    const siteInfo = localStorage.getItem('siteInfo') ? JSON.parse(localStorage.getItem('siteInfo')) : undefined;
    if (siteInfo) {
      setStoreInformation(siteInfo);
    }
    fetch(API.settings.load, {
      method: "POST",
      headers: {
        "x-auth-token": localStorage.getItem("token"),
        "x-site-id": sessionStorage.getItem('currentSite') ? JSON.parse(sessionStorage.getItem('currentSite'))._id : '',
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        key: "GENERAL",
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (!response.error){
          localStorage.setItem('siteInfo', JSON.stringify(response))
          if (JSON.stringify(siteInfo) !== JSON.stringify(response)) {
            setStoreInformation(response);
          }
        }
      })
      .catch((e) => {});
  }, []);
  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-12">
                <div className="invoice-title">
                  <h4 className="float-right font-size-16">
                    <strong>
                      {MyAccountText.orderDetails.COMMANDE_TEXT} #{order.reference}
                    </strong>
                  </h4>
                  <h3 className="mt-0">
                    <img src={storeInformation ? STATIC.images(storeInformation.logo) : images.logo} alt="logo" height={32} />
                  </h3>
                </div>
                <hr />
                <div className="row">
                  <div className="col-6 mt-4">
                    <address>
                      <strong>
                        {MyAccountText.orderDetails.PAYEMENT_MODE} :
                      </strong>
                      <br />
                      {order.paymentGateway}
                    </address>
                    <div />
                  </div>
                  <div className="col-6 mt-4 text-right">
                    <address>
                      {
                        order.user && order.user.reseller && order.user.resellerRef !== ''?
                          <div>
                          <strong>
                            {MyAccountText.orderDetails.REFERENCE}: {order.resellerRef}
                          </strong>
                          <br/>
                          </div>
                        :
                          <></>  
                      }
                      <strong>
                        {MyAccountText.orderDetails.COMMANDE_DATE}:
                      </strong>
                      <br />
                      {moment(order.created_at)
                        .locale("fr")
                        .format("ddd DD MMMM YYYY HH:MM")}
                      <br />
                      <strong>
                        {MyAccountText.orderDetails.DELIVERY_DATE}:
                      </strong>
                      <br />
                      {moment(order.date)
                        .locale("fr")
                        .format("ddd DD MMMM YYYY HH:MM")}
                      <br />
                      <br />
                    </address>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div>
                  <div className="p-2">
                    <h3 className="font-size-16">
                      <strong>{MyAccountText.orderDetails.PRODUCT_LIST}</strong>
                    </h3>
                  </div>
                  <div>
                    <div className="table-responsive">
                      <Table className="table">
                        <thead>
                          <tr>
                            <th>{CheckoutText.orderDetails.PRODUCT_TEXT}</th>

                            <th>
                              {
                                CheckoutText.orderDetails
                                  .PRICE_AND_QUANTITY_TEXT
                              }
                            </th>

                            <th>{CheckoutText.orderDetails.TOTAL_TEXT}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {contentProducts.map((elem) => {
                            return (
                              <tr key={elem._id}>
                                <td data-title="Product">
                                  <div>
                                    <div>
                                      <h6>
                                        {" "}
                                        <a href="/">{elem.product.name}</a>{" "}
                                      </h6>
                                    </div>
                                  </div>
                                </td>

                                <td data-title="Quantity">
                                  {Number(elem.product.price).toFixed(2)}€ x
                                  {elem.qte}
                                </td>
                                <td data-title="Total">
                                  {" "}
                                  <strong>
                                    {Number(
                                      elem.product.price * elem.qte
                                    ).toFixed(2)}
                                    €
                                  </strong>{" "}
                                </td>
                              </tr>
                            );
                          })}

                          <tr className="total">
                            <td>
                              <h6 className="mb-0">
                                {CheckoutText.orderDetails.BIG_TOTAL}
                              </h6>
                            </td>
                            <td></td>

                            <td>
                              {" "}
                              <strong>{order.total}€</strong>{" "}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetail;
