import React from "react";
import { NotFoundContainer, NotFoundText } from "../NotFound/style";
import { StyledLink } from "../CustomButton/style";
import { PaymentSucceedText } from "../../constants/texts";
import { destroyCart, getCart } from "../../store/actions/cartActions";
import { withRouter, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { API } from '../../constants/env';
import { OrderDetails, OrderDetailsItem, OrderContentTitle, OrderTable, ProductLink, OrderTableTH, OrderItemData, OrderDetailsTH, TableClient, TableTH, TableTD, ValidIcon } from './style';
import { getProducts } from "../../store/Selectors";
import moment from 'moment';

function PaymentSucceed(props) {
  const [loading, setLoading] = React.useState(true);
  const [order, setOrder] = React.useState({});
  const [content, setContent] = React.useState([]);
  React.useEffect(() => {
    props.getCart();
    setTimeout(() => props.destroyCart(), 2000);
  }, []);
  React.useEffect(() => {
    if (props.content && props.content.length > 0) {
      setContent(props.content);
      setLoading(false);
    }
  }, [props.content]);
  React.useEffect(() => {
    setOrder(props.order);
    setLoading(false);
  }, [props.order]);
  const calculateTotal = (product) => {
    let total = 0;
    total = product.price;
    for (const option of product.selectedSupplements) {
      for (const item of option.items) {
        total += item.price;
      }
    }
    return total * product.quantity;
  }
  const calculateSubTotal = (content) => {
    let total = 0;
    for (const product of content) {
      total += calculateTotal(product);
    }
    return total;
  }
  const renderOrder = () => (
    order ?
      <div>
        <OrderDetails>
          <OrderDetailsItem>
            Commande:
              <strong>#{order.reference}</strong>
          </OrderDetailsItem>
          {order.resellerRef && order.resellerRef.length > 0 ?
            <OrderDetailsItem>
              Réference:
              <strong>{order.resellerRef}</strong>
            </OrderDetailsItem>
            :
            <></>
          }
          <OrderDetailsItem>
            Date:
              <strong>{moment(order.date).format('DD/MM/YYYY HH:mm')}</strong>
          </OrderDetailsItem>
          <OrderDetailsItem>
            Totale:
              <strong>{order.total}</strong>
          </OrderDetailsItem>
          <OrderDetailsItem>
            Mode Paiement:
              <strong>{order.paymentGateway}</strong>
          </OrderDetailsItem>

        </OrderDetails>
        <OrderContentTitle>Détails de la commande</OrderContentTitle>
        <OrderTable>
          <thead>
            <tr>
              <OrderTableTH>PRODUIT</OrderTableTH>
              <OrderTableTH>TOTAL</OrderTableTH>
            </tr>
          </thead>
          <tbody>
            {
              content.map(
                contentData => (
                  <tr>
                    <OrderItemData>
                      <ProductLink href=''>{contentData.name}</ProductLink>
                      <strong> x{contentData.quantity}</strong>
                      {
                        contentData.selectedOptions.map(
                          option => (<dl>
                            <dt><span>{option.name}</span></dt>
                            <dd>{option.items.map((item, index) => `${item.name}${index < option.items.length - 1 ? ', ' : ''}`)}</dd>
                          </dl>))
                      }
                      {
                        contentData.selectedSupplements.map(
                          option => (<dl>
                            <dt><span>{option.name}</span></dt>
                            <dd>{option.items.map((item, index) => `${item.name}(+${item.price}€)${index < option.items.length - 1 ? ', ' : ''}`)}</dd>
                          </dl>))
                      }
                    </OrderItemData>
                    <OrderItemData>
                      <strong>{calculateTotal(contentData)}€</strong>
                    </OrderItemData>
                  </tr>
                )
              )
            }
          </tbody>
          <tfoot>
            <tr>
              <OrderDetailsTH>Sous Total</OrderDetailsTH>
              <OrderDetailsTH>
                <strong>{calculateSubTotal(content)}€</strong>
              </OrderDetailsTH>
            </tr>
            <tr>
              <OrderDetailsTH>Mode Paiement</OrderDetailsTH>
              <OrderDetailsTH>
                <strong>{order.paymentGateway}</strong>
              </OrderDetailsTH>
            </tr>
            {
              !order.pickup ?
                <tr>
                  <OrderDetailsTH>Frais de livraison</OrderDetailsTH>
                  <OrderDetailsTH>
                    <strong>{order.total - calculateSubTotal(content) === 0 ? 'Gratuite' : `${Number(order.total - calculateSubTotal(content)).toFixed(2)}€`}</strong>
                  </OrderDetailsTH>
                </tr>
                :
                <div />
            }
            <tr>
              <OrderDetailsTH>Total</OrderDetailsTH>
              <OrderDetailsTH>
                <strong>{order.total}€</strong>
              </OrderDetailsTH>
            </tr>
          </tfoot>
        </OrderTable>
        <OrderContentTitle>Informations Client</OrderContentTitle>
        <TableClient>
          <tbody>
            <tr>
              <TableTH>Nom</TableTH>
              <TableTD>{order.client.firstName} {order.client.lastName}</TableTD>
            </tr>
            <tr>
              <TableTH>Email</TableTH>
              <TableTD>{order.client.email}</TableTD>
            </tr>
            <tr>
              <TableTH>Téléhone</TableTH>
              <TableTD>{order.client.phone}</TableTD>
            </tr>
          </tbody>
        </TableClient>
      </div>
      :
      <div />
  )
  return (
    loading ?
      <h1>Loading</h1>
      :
      <div class="container" style={{ paddingBottom: 25 }}>
        <NotFoundText>
          <img src="/baking.gif" />
          {/*<img src="https://i.giphy.com/media/WRXNJYnmTfaCUsU4Sw/giphy.webp" style={{height: 150}}/>*/}
          <h1>{PaymentSucceedText.TITLE_TEXT}</h1>

          <p>{PaymentSucceedText.CONTENT_TEXT}</p>
        </NotFoundText>
        {renderOrder()}
        <NotFoundText>
          <StyledLink to="/">{PaymentSucceedText.BUTTON_TEXT}</StyledLink>
        </NotFoundText>
      </div>
  );
}
const mapStateToProps = (state) => {
  const newState = { content: getProducts(state) };
  if (state.orderReducer && state.orderReducer.order) {
    newState.order = state.orderReducer.order;
  }
  return newState;
};
const mapDispatchToProps = (dispatch) => {
  return {
    destroyCart: () => {
      dispatch(destroyCart());
    },
    getCart: () => {
      dispatch(getCart());
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PaymentSucceed));
