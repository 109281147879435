import React from 'react'
import {SideDrawerNav} from './style';
import {navbarRoutes} from '../../constants/routes'
import { Link } from "react-router-dom";
import { images, STATIC, API } from '../../constants/env';
function SideDrawer(props) {
    let drawerClasses;
    if(props.showValue){
        drawerClasses='drawer-transtion'
    }
    const [storeInformation, setStoreInformation] = React.useState({
        adress: "124 Avenue Parmentier, 75011 Paris, France",
        email: "olivier@klikx.lol",
        formatedAdress: "124 Avenue Parmentier, 75011 Paris, France",
        logo: "/statics/product-1597486560136.png",
        name: "Klikx",
        phone: "516467464",
      });
      React.useEffect(() => {
        const siteInfo = localStorage.getItem('siteInfo') ? JSON.parse(localStorage.getItem('siteInfo')) : undefined;
        if (siteInfo) {
          setStoreInformation(siteInfo);
        }
        fetch(API.settings.load, {
          method: "POST",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
            "x-site-id": sessionStorage.getItem('currentSite') ? JSON.parse(sessionStorage.getItem('currentSite'))._id : '',
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            key: "GENERAL",
          }),
        })
          .then((res) => res.json())
          .then((response) => {
            if (!response.error){
              localStorage.setItem('siteInfo', JSON.stringify(response))
              if (JSON.stringify(siteInfo) !== JSON.stringify(response)) {
                setStoreInformation(response);
              }
            }
          })
          .catch((e) => {});
      }, []);
    return (
        <SideDrawerNav className={drawerClasses}>
            <a href="/">
                <img src={props.siteInfo ? STATIC.images(props.siteInfo.logo) : images.logo} alt="logo"/>
            </a>
            <ul>
            {
              navbarRoutes.map((elem, index )=> (
                <li key={index}>
                 <Link to={elem.path} onClick = {props.backdropClick}>{elem.name}</Link>
                 </li>    
              ))
            }
           </ul>
       </SideDrawerNav>
    )
}

export default SideDrawer
