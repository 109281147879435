import React from "react";
import {
  CartTableContainer,
  Table,
  ProductItem,
  ProductBody,
  QuanitiyAddSub,
} from "./style";
import { CloseButton } from "../Modal/style";
import { TableText } from "../../../constants/texts";
import { STATIC } from "../../../constants/env";
function createMarkup(elem) {
  return { __html: elem };
}
const CartTable = (props) => {
  const handleSetQuantity = (event, quantity, item) => {
    const value = event.target.value;
    if (value > quantity) {
      props.addQuantity(item);
    } else {
      props.subtractQuantity(item);
    }
  };
  const handleAddQuantity = (event, quantity, item) => {
    props.addQuantity(item);
  };
  const handleSubQuantity = (event, quantity, item) => {
    if (quantity > 1) {
      props.subtractQuantity(item);
    }
  };

  return (
    <CartTableContainer>
      <Table>
        <thead>
          <tr>
            <th></th>
            <th>{TableText.cartTable.PRODUCT}</th>
            <th>{TableText.cartTable.PRICE}</th>
            <th>{TableText.cartTable.QUANTITE}</th>
            <th>{TableText.cartTable.TOTAL}</th>
          </tr>
        </thead>
        <tbody>
          {props.items.map((elem, index) => {
            let items = [];
            let total = 0;
            if (elem.selectedOptions && elem.selectedOptions.length > 0) {
              elem.selectedOptions.forEach((elem) => {
                elem.items.forEach((elem, index, array) => {
                  items.push(elem.name);
                  items.push(", ");
                  total += elem.price;
                });
              });
            }
            if (elem.selectedSupplements && elem.selectedSupplements.length > 0) {
              elem.selectedSupplements.forEach((elem, index, array) => {
                elem.items.forEach((elem, index, array) => {
                  items.push(elem.name);
                  items.push(", ");
                  total += elem.price;
                });
              });
            }
            items.pop();
            return (
              <tr key={elem.key_id}>
                <td className="remove">
                  <CloseButton
                    className="table-cart"
                    onClick={() => props.removeItem(index)}
                  >
                    <span></span>
                    <span></span>
                  </CloseButton>
                </td>
                <td data-title="Produit">
                  <ProductItem>
                    <img src={STATIC.images(elem.picture)} alt="prod1" />
                    <ProductBody>
                      <h6>{elem.name}</h6>
                      <div
                        dangerouslySetInnerHTML={createMarkup(elem.description)}
                      />
                      <span>{items}</span>
                      <span><br />{elem.instructions}</span>
                    </ProductBody>
                  </ProductItem>
                </td>
                <td data-title="Prix">
                  <strong>{Number(elem.price).toFixed(2)}€</strong>
                </td>
                <td className="quantity" data-title="Quantite">
                  <QuanitiyAddSub className="d-flex">
                    <input
                      type="number"
                      className="form-control"
                      min="0"
                      value={elem.quantity}
                      onChange={(e) =>
                        handleSetQuantity(e, elem.quantity, elem)
                      }
                    />
                    <div>
                      <button
                        onClick={(e) =>
                          handleAddQuantity(e, elem.quantity, index)
                        }
                      >
                        <span>&#8249;</span>
                      </button>
                      <button
                        onClick={(e) =>
                          handleSubQuantity(e, elem.quantity, index)
                        }
                      >
                        <span>&#8250;</span>
                      </button>
                    </div>
                  </QuanitiyAddSub>
                </td>
                <td data-title="Total">
                  <strong>
                    {Number((elem.price + total) * elem.quantity).toFixed(2)}€
                  </strong>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </CartTableContainer>
  );
};
export default CartTable;
