import {
  ADD_TO_CART,
  REMOVE_ITEM,
  SUB_QUANTITY,
  ADD_QUANTITY,
  DESTROY_CART,
  VERIFY_PRODUCT_CART,
  GET_CART,
} from "../actions/action-types/cart-actions";
const _ = require("lodash");
const initialState = {
  addedItems: [],
};

const cartReducer = (state = initialState, action) => {
  if (action.type === ADD_TO_CART) {
    let addedItem = action.payload.item;
    const findIndex = state.addedItems.findIndex(
      (elem) =>
        elem._id === addedItem._id &&
        _.isEqual(elem.selectedSupplements, addedItem.selectedSupplements) &&
        _.isEqual(elem.selectedOptions, addedItem.selectedOptions) &&
        elem.instructions === addedItem.instructions
    );

    if (findIndex >= 0) {
      return {
        ...state,

        addedItems: state.addedItems.map((item, index) =>
          index === findIndex
            ? { ...item, quantity: item.quantity + addedItem.quantity }
            : // otherwise return original todo
              item
        ),
      };
    } else {
      return {
        ...state,
        addedItems: [...state.addedItems, addedItem],
      };
    }
  }

  if (action.type === REMOVE_ITEM) {
    return {
      ...state,
      addedItems: [
        ...state.addedItems.slice(0, action.payload.index),
        ...state.addedItems.slice(action.payload.index + 1),
      ],
    };
  }
  if (action.type === GET_CART) {
    return {
      ...state,
      addedItems: state.addedItems,
    };
  }
  if (action.type === ADD_QUANTITY) {
    return {
      ...state,
      addedItems: state.addedItems.map((item, index) =>
        index === action.payload.index
          ? { ...item, quantity: item.quantity + 1 }
          : item
      ),
    };
  }
  if (action.type === SUB_QUANTITY) {
    const item = state.addedItems[action.payload.index];
    if (item.quantity === 1) {
      return {
        ...state,
        addedItems: [
          ...state.addedItems.slice(0, action.payload.index),
          ...state.addedItems.slice(action.payload.index + 1),
        ],
      };
    } else {
      return {
        ...state,
        addedItems: state.addedItems.map((item, index) =>
          index === action.payload.index
            ? { ...item, quantity: item.quantity - 1 }
            : item
        ),
      };
    }
  }
  if (action.type === DESTROY_CART) {
    return {
      ...state,
      addedItems: [],
    };
  }
  if (action.type === VERIFY_PRODUCT_CART) {
    return {
      ...state,
      addedItems: action.payload.items,
    };
  } else {
    return state;
  }
};
export default cartReducer;
