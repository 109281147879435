import {
  SAVE_ORDER,
  GET_ORDER,
} from "../actions/action-types/order-actions";
const initialState = {
  order: [],
};

const orderReducer = (state = initialState, action) => {
  if (action.type === SAVE_ORDER) {
    let order = action.payload.order;
    return {
        ...state,
        order,
    };
  } else if (action.type === GET_ORDER) {
    return {
      ...state,
    }
  } else {
    return {...state}
  }
}
export default orderReducer;