import styled from "styled-components";
import { CloseButton } from "../Cart/Modal/style";
const SearchContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: ${(props) => props.theme.search.bgColor};
  padding: 20px;
  opacity: 0;
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: 0.3s;

  &.open {
    opacity: 1;
    visibility: visible;
  }
`;

const Close = styled(CloseButton)`
  position: absolute;
  top: 60px;
  right: 60px;
  span {
    width: 2px;
    height: 40px;
  }
`;
const SearchForm = styled.form`
  position: relative;
  width: 70%;
  transition-duration: 0.3s;
  transition-delay: 0.3s;
  transform: scale(0.5);
  opacity: 0;
  visibility: hidden;

  &.open {
    transform: scale(1);
    opacity: 1;
    visibility: visible;
  }
  input {
    background-color: transparent;
    border: 0;
    border-bottom: 2px solid ${(props) => props.theme.search.borderInputColor};
    padding: 20px 0;
    font-size: 40px;
    width: 100%;
    outline: none;
    color: ${(props) => props.theme.search.inputColor};
  }

  button {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background-color: transparent;
    border: 0;
    color: ${(props) => props.theme.search.buttonColor};
    font-size: 30px;
    outline: none;
    transition: 0.3s;
    cursor: pointer;
    padding: 0;
  }
`;
const SearchProducts = styled.div`
  padding: 80px 0 50px;
  text-align: center;
`;
export { SearchContainer, Close, SearchForm, SearchProducts };
