import styled from "styled-components";
const CartTableContainer = styled.div`
  margin-bottom: 10px;
  padding: 80px 0;
  @media (max-width: 991px) {
    padding: 60px 0;
    /* margin: 0 15px 0 auto;*/
  }
`;
const Table = styled.table`
  width: 100%;
  th {
    font-weight: 600;
    color: ${(props) => props.theme.cart.CartTable.thColor};
    background-color: ${(props) => props.theme.cart.CartTable.thBgColor};
    font-family: "Oswald", sans-serif;
    padding: 15px;
    vertical-align: middle;
  }
  td {
    background-color: ${(props) => props.theme.cart.CartTable.tdColorBgColor};
    border-bottom: 1px solid
      ${(props) => props.theme.cart.CartTable.tdBorderBottomColor};
    padding: 15px;
    vertical-align: middle;
    input {
      height: 50px;
      padding: 8px 15px;
      border-radius: 8px;
      border: 1px solid
        ${(props) => props.theme.cart.CartTable.quantiteInputBorderColor};
      width: 100%;
      color: ${(props) => props.theme.cart.CartTable.quantiteInputColor};
      background-color: ${(props) =>
        props.theme.cart.CartTable.quantiteInputBGColor};
      letter-spacing: 0.1px;
      font-size: 15px;
    }
  }

  td.remove {
    width: 30px;
  }
  td.quantity {
    width: 150px;
  }

  @media (max-width: 768px) {
    thead {
      display: none;
    }
    thead > tr:last-child {
      border-bottom: 0;
    }
    thead > tr {
      border-bottom: 1px solid
        ${(props) => props.theme.cart.CartTable.tdBorderBottomColor};
    }
    tbody tr {
      border-bottom: 1px solid
        ${(props) => props.theme.cart.CartTable.tdBorderBottomColor};
    }
    tbody td.remove {
      width: 100%;
      &::before {
        content: "";
      }
    }
    tbody td.quantity {
      width: 100%;
    }
    tbody td {
      position: relative;
      display: block;
      width: 100%;
      border-top: 0;
      text-align: right;
      padding: 10px;
      border-bottom: 0;
      &::before {
        content: attr(data-title) ": ";
        font-weight: 700;
        float: left;
        @media (max-width: 768px) {
          display: none;
        }
      }
    }
  }
`;

const ProductItem = styled.div`

    display:flex;
    align-items:center;
    @media (max-width: 768px) {
   
    align-items:center;
    padding: 0;
    text-align: left;
   
    }
    img{
        width: 50px;
        margin-right: 30px;
        border-radius:8px;
        margin-left: 20px;

    }
    }
`;
const ProductBody = styled.div`
  flex: 1;
  h6 {
    font-size: 16px;
    margin: 0 0 5px;
    font-weight: 700;
    color: ${(props) => props.theme.cart.CartTable.productBody.titleColor};
  }
  p,
  span {
    margin-bottom: 0;
    font-size: 13px;
    line-height: 21px;
    color: ${(props) => props.theme.cart.CartTable.productBody.textColor};
  }
`;

const QuanitiyAddSub = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    border: none;
    outline: 0;
    background: 0 0;
    text-align: center;
    display: inline-block;
    text-align: center;
  }
  button span {
    display: block;
    transform: rotate(90deg);
    font-size: 25px;
    text-align: center;
  }
  div {
    margin-left: 10px;
  }
`;
export { CartTableContainer, Table, ProductItem, ProductBody, QuanitiyAddSub };
