import { createGlobalStyle } from "styled-components";
export const GlobalStyle = createGlobalStyle`
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.form-check-input {
    margin-top: 0.5rem;
}
body {
    font-family: 'Open Sans', sans-serif;
    margin: 0;
    padding: 0;
    font-size: 15px;
    line-height: 28px;
    font-weight: 400;
    color: ${(props) => props.theme.global.bodyColor};
    overflow-x: hidden;
}
a , div, header, ul, li {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  text-decoration:none;
  font-size: 100%;
}
h1, h2, h3, h4, h5, h6 {
    margin: 0 0 20px;
    font-family: 'Oswald', serif;
    font-weight: 700;
    line-height: 28px;
    color: ${(props) => props.theme.global.hColor};
}

.form-control::placeholder{
  color: ${(props) => props.theme.globalStyle.formControl.placeholderColor};
}
.form-control {
  height: 50px;
  padding: 8px 15px;
  border-radius: 8px;
  border: 1px solid ${(props) =>
    props.theme.globalStyle.formControl.borderColor};
  width: 100%;
  color: ${(props) => props.theme.globalStyle.formControl.color};
  background-color: ${(props) =>
    props.theme.globalStyle.formControl.backgroundColor};
  letter-spacing: 0.1px;
  font-size: 15px;
}
.form-control:focus {
  border: 1px solid ${(props) =>
    props.theme.globalStyle.formControlFocus.borderColor};
  box-shadow: none;
}


`;
