import {
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  RESET_STATE,
} from "./action-types/auth-actions";
import { API } from "../../constants/env";
import { loginFormErrors } from "../../constants/errors";

export const requestLogin = (creds) => {
  return {
    type: LOGIN_REQUEST,
    isFetching: true,
    isAuthenticated: false,
    creds,
  };
};

export const receiveLogin = (user) => {
  return {
    type: LOGIN_SUCCESS,
    isFetching: false,
    isAuthenticated: true,
    id_token: user.token,
  };
};

export const loginError = (message) => {
  return {
    type: LOGIN_FAILURE,
    isFetching: false,
    isAuthenticated: false,
    message,
  };
};

// calls api

export const loginUser = (creds, history, rememberMe) => {
  let config = {
    method: "POST",
    headers: { "Content-Type": "application/json", "x-site-id": sessionStorage.getItem('currentSite') ? JSON.parse(sessionStorage.getItem('currentSite'))._id : '', },
    body: JSON.stringify(creds),
  };
  return (dispacth) => {
    dispacth(requestLogin(creds));
    return fetch(API.auth.login, config)
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          let errorMessage = "";
          if (response.message === "Wrong username/password!") {
            errorMessage = loginFormErrors.serverError;
          } else {
            errorMessage = response.message;
          }
          dispacth(loginError(errorMessage));
          return Promise.reject(response);
        } else {
          if (rememberMe === true) {
            localStorage.setItem("id_token", response.token);
          } else {
            sessionStorage.setItem("id_token", response.token);
          }
          // Dispatch the success action
          dispacth(receiveLogin(response));
          if (history) {
            history.push("/account");
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
};

// logout

export const requesLogout = () => {
  return {
    type: LOGOUT_REQUEST,
    isFetching: true,
    isAuthenticated: true,
  };
};

export const receiveLogout = () => {
  return {
    type: LOGOUT_SUCCESS,
    isFetching: false,
    isAuthenticated: false,
  };
};

// log the user out

export const logoutUser = (history) => {
  return (dispatch) => {
    dispatch(requesLogout());
    if (sessionStorage.getItem("id_token")) {
      sessionStorage.removeItem("id_token");
    } else {
      localStorage.removeItem("id_token");
    }
    dispatch(receiveLogout());
    history.push("/");
  };
};
export const resetState = () => {
  return {
    type: RESET_STATE,
  };
};
