import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { setSubHeader } from "../../store/actions/stickyNavbarAction";
import About from "../../components/About/index";
import { SubHeaderText } from "../../constants/texts";
import { images, API, STATIC } from "../../constants/env";
const AboutPage = (props) => {
  const menus = [
    { link: "/", title: SubHeaderText.HOME },
    { link: "/about", title: SubHeaderText.about.TITLE },
  ];
  const [content, setContent] = useState(
    localStorage.getItem("ABOUT")
      ? JSON.parse(localStorage.getItem("ABOUT"))
      : {}
  );

  useEffect(() => {
    const fetchData = () => {
      fetch(API.content.load, {
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
          "x-site-id": sessionStorage.getItem('currentSite') ? JSON.parse(sessionStorage.getItem('currentSite'))._id : '',
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: "ABOUT",
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          if (JSON.stringify(response) !== localStorage.getItem("ABOUT")) {
            localStorage.setItem("ABOUT", JSON.stringify(response));
            setContent(response);
          }
        })
        .catch((e) => {});
    };
    fetchData();
  }, []);
  React.useEffect(() => {
    props.setSubHeader(
      true,
      content.title,
      STATIC.images(content.subHeaderImage),
      menus
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);
  return <About content={content} />;
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSubHeader: (show, title, bgImage, menus) => {
      dispatch(setSubHeader(show, title, bgImage, menus));
    },
  };
};
export default connect(null, mapDispatchToProps)(AboutPage);
