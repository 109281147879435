/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import HowWeDo from "../../components/HowWeDoSection/index";
import Banner from "../../components/Banner/v1/index";
import Trending from "../../components/Trending/index";
import NewsLetter from "../../components/NewsLetter/index";
import { TrendingText } from "../../constants/texts";
import { connect } from "react-redux";
import {
  addToCart,
  removeItem,
  addQuantity,
  subtractQuantity,
} from "../../store/actions/cartActions";
import { setSubHeader } from "../../store/actions/stickyNavbarAction";
import {
  fetchProducts,
  setTodayProducts,
} from "../../store/actions/productsActions";
import Preloader from "../../components/Preloader/index";
import ProductOptions from "../../components/ProductOptions/index";
import Backdrop from "../../components/Backdrop/index";
import { API } from "../../constants/env";
function HomePage(props) {
  const [suggestion, setSuggestion] = React.useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [product, setProduct] = useState({});
  const [content, setContent] = useState(
    localStorage.getItem("HOME") ? JSON.parse(localStorage.getItem("HOME")) : {}
  );

  useEffect(() => {
    const fetchData = () => {
      fetch(API.content.load, {
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
          "x-site-id": sessionStorage.getItem('currentSite') ? JSON.parse(sessionStorage.getItem('currentSite'))._id : '',
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: "HOME",
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          console.log("this is slides data ");
          console.log(response);
          if (JSON.stringify(response) !== localStorage.getItem("HOME")) {
            localStorage.setItem("HOME", JSON.stringify(response));
            setContent(response);
          }
        })
        .catch((e) => {});
    };
    fetchData();
  }, []);
  const [slides, setSlides] = useState(
    localStorage.getItem("SLIDES")
      ? JSON.parse(localStorage.getItem("SLIDES"))
      : {}
  );

  useEffect(() => {
    const fetchData = () => {
      fetch(API.content.load, {
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
          "x-site-id": sessionStorage.getItem('currentSite') ? JSON.parse(sessionStorage.getItem('currentSite'))._id : '',
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: "SLIDES",
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          console.log("this is slides data ");
          console.log(response);
          if (JSON.stringify(response) !== localStorage.getItem("SLIDES")) {
            localStorage.setItem("SLIDES", JSON.stringify(response));
            setSlides(response);
          }
        })
        .catch((e) => {});
    };
    fetchData();
  }, []);
  useEffect(() => {
    props.setSubHeader(false);
    props.fetchProducts();
  }, []);
  useEffect(() => {
    props.setTodayProducts();
  }, [props.productsList]);
  /*
    useEffect(()=>{
        if(props.loading === false){
            setIsLoading(false)

        }
    }, [props.loading])
    */
  React.useEffect(() => {
    if (props.productsList.length > 7) {
      const arr = getRandom(props.productsList, 7);
      setSuggestion(arr);
    }
  }, [props.productsList]);
  const handleAddToCart = (item) => {
    props.addToCart(item);
  };
  const getRandom = (arr, n) => {
    let result = new Array(n),
      len = arr.length,
      taken = new Array(len);
    if (n > len)
      throw new RangeError("getRandom: more elements taken than available");
    while (n--) {
      var x = Math.floor(Math.random() * len);
      result[n] = arr[x in taken ? taken[x] : x];
      taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
  };
  const handleShowOptions = (item) => {
    setProduct(item);
    setShowOptions(true);
    setShowBackdrop(true);
  };
  const handleCloseOption = () => {
    setShowOptions(false);
    setShowBackdrop(false);
  };
  return (
    <>
      {showOptions && (
        <ProductOptions
          product={product}
          closeOption={handleCloseOption}
          addToCart={props.addToCart}
        />
      )}
      {showBackdrop && <Backdrop backdropClick={handleCloseOption} />}

      {props.loading ? (
        <Preloader />
      ) : (
        <div>
          <Banner suggestion={suggestion} slides={slides} />
          <HowWeDo content={content} />
          <Trending
            subtitle={TrendingText.SUBTITLE}
            title={TrendingText.TITLE}
            paragraph={TrendingText.PARAGRAPH}
            productsList={props.todayProducts}
            addToCart={handleAddToCart}
            handleShowOptions={handleShowOptions}
          />
          {/*
            <NewsLetter />
            */}
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    items: state.cartReducer.addedItems,
    todayProducts: state.productsReducer.todayProducts,
    productsList: state.productsReducer.productsList,
    loading: state.productsReducer.loading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    removeItem: (item) => {
      dispatch(removeItem(item));
    },
    addQuantity: (item) => {
      dispatch(addQuantity(item));
    },
    subtractQuantity: (item) => {
      dispatch(subtractQuantity(item));
    },
    setSubHeader: (show) => dispatch(setSubHeader(show)),
    addToCart: (item) => {
      dispatch(addToCart(item));
    },
    fetchProducts: () => {
      dispatch(fetchProducts());
    },
    setTodayProducts: () => {
      dispatch(setTodayProducts());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
