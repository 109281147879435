import React, { useState } from "react";
import { MyAccountText } from "../../../constants/texts";
import { StyledButton } from "../../CustomButton/style";
import { useForm } from "react-hook-form";
import { API } from "../../../constants/env";
import { formErrors } from "../../../constants/errors";
import { updatePasswordMessage } from "../../../constants/succesMessage";
import { getToken } from "../../../services/LoadToken";
const Password = (props) => {
  const { register, handleSubmit, errors, watch } = useForm();
  const [isError, setIsError] = React.useState(false);
  const [showSucces, setShowSucces] = React.useState(false);
  const [errorServer, setErrorServer] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [buttonWidth, setButtonWidth] = useState("");
  React.useEffect(() => {
    const width = document.getElementById("update").offsetWidth;
    setButtonWidth(width + "px");
  }, []);
  const watchAllFields = watch();

  const onSubmit = (data) => {
    setIsLoading(true);

    const token = getToken();
    //const formData = new FormData();
    const sendData = {
      oldPassword: data.oldPassword,
      password: data.newPassword,
      user: props.user,
    };
    fetch(API.me.updatePassword, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
        "x-site-id": sessionStorage.getItem('currentSite') ? JSON.parse(sessionStorage.getItem('currentSite'))._id : '',
      },
      body: JSON.stringify(sendData),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          setShowSucces(false);
          setErrorServer(response.message);
          setIsError(true);

          setIsLoading(false);
          setTimeout(() => {
            setIsError(false);
          }, 4000);
        } else {
          setIsError(false);
          setShowSucces(true);
          setIsLoading(false);
          setTimeout(() => {
            setShowSucces(false);
          }, 4000);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsLoading(false);
      });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-group row">
        <label className="col-lg-3 font-weight-bold text-dark col-form-label form-control-label text-2 required">
          {MyAccountText.myProfil.ACTUAL_PASSWORD}
        </label>
        <div className="col-lg-9">
          <input
            autoComplete="off"
            className="form-control"
            name="oldPassword"
            ref={register({
              required: {
                value: true,
                message: formErrors.passwordRequired,
              },
            })}
            type="password"
          />
          {errors.oldPassword && errors.oldPassword.message}
        </div>
      </div>
      <div className="form-group row">
        <label className="col-lg-3 font-weight-bold text-dark col-form-label form-control-label text-2 required">
          {MyAccountText.myProfil.NEW_PASSWORD_TEXT}
        </label>
        <div className="col-lg-9">
          <input
            autoComplete="off"
            className="form-control"
            type="password"
            name="password"
            ref={register({
              required: {
                value: true,
                message: formErrors.passwordRequired,
              },
            })}
          />
          {errors.password && errors.password.message}
        </div>
      </div>
      <div className="form-group row">
        <label className="col-lg-3 font-weight-bold text-dark col-form-label form-control-label text-2 required">
          {MyAccountText.myProfil.CONFIRM_PASSWORD_TEXT}
        </label>
        <div className="col-lg-9">
          <input
            className="form-control"
            name="newPassword"
            type="password"
            ref={register({
              validate: (value) => value === watchAllFields.password,
              required: true,
            })}
          />
          {errors.newPassword && formErrors.passwordIsNotIdentique}
        </div>
      </div>
      {isError ? (
        <div className="alert alert-danger" role="alert">
          {errorServer}
        </div>
      ) : (
        <div />
      )}
      {showSucces ? (
        <div className="alert alert-success" role="alert">
          {updatePasswordMessage}{" "}
        </div>
      ) : (
        <div />
      )}
      <div className="form-group row">
        <div className="form-group col-lg-8" />
        <div className="form-group col-lg-4 ">
          <StyledButton
            type="submit"
            className={`float-right ${isLoading ? "loading" : ""}`}
            disabled={isLoading ? true : false}
            width={buttonWidth}
            id="update"
          >
            {isLoading ? (
              <i className="fa fa-spinner fa-spin"></i>
            ) : (
              MyAccountText.BUTTON_TEXT
            )}
          </StyledButton>
        </div>
      </div>
    </form>
  );
};

export default Password;
