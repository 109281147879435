import React from 'react'
//import { Container, ModalMain, ModalHeader, ModalFooter } from './style';
import { StyledButton } from "../CustomButton/style";
import { } from '../MapsModal/style'
//import { ButtonContainer, SaveButton } from "../CroppedImage/style";
import { CloseButton } from "../Cart/Modal/style";
import {
    ModalContainer,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from "../MapsModal/style";
import {profileImageModal} from '../../constants/texts'
const CroppedImageModal = ({ show, handleClose, fileChangedHandler, fileCrop, children }) => {
    const showHideClassName = show ? "display-block" : "display-none"

    return (
        <ModalContainer>
            <CloseButton className="product-options" onClick={handleClose}>
                <span />
                <span />
            </CloseButton>
            <ModalHeader>
            <h2>{profileImageModal.title}</h2>
            </ModalHeader>

            <ModalBody>
                <span></span>

                {children}
            </ModalBody>

            <ModalFooter>
                <StyledButton onClick={() => {
                    fileChangedHandler(fileCrop);
                    handleClose();
                }} className="mapsModal">
                    {profileImageModal.buttonConfirm}
        </StyledButton>
            </ModalFooter>
        </ModalContainer>


    )
}

export default CroppedImageModal

/*
<Container className={showHideClassName}>
    <ModalHeader>

    </ModalHeader>
    <ModalMain>
        <div>
         {children}

        </div>
        <ButtonContainer>
            <StyledButton className="btn-sm" onClick={handleClose}>
                close
        </StyledButton>
            <SaveButton
                className="btn-sm"
                onClick={() => {
                    fileChangedHandler(fileCrop);
                    handleClose();
                }}
            >
                save
        </SaveButton>
        </ButtonContainer>
    </ModalMain>
    <ModalFooter>

    </ModalFooter>

</Container>
*/
