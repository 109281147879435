import React from "react";
import {
  LoginContainer,
  AuthDescription,
  AuthForm,
  AuthDescriptionContent,
  LoginSection,
} from "../Login/style";
import { useForm } from "react-hook-form";
import { ResetPasswordText } from "../../constants/texts";
import { formErrors } from "../../constants/errors";
import { StyledButton } from "../CustomButton/style";
import { API } from "../../constants/env";
import { resetPasswordMessage } from "../../constants/succesMessage";
import { Error } from "../FormError/style";
import { resetPassword } from "../../constants/errors";
import { useHistory, useLocation } from "react-router-dom";
import { STATIC } from "../../constants/env";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const ResetPassword = (props) => {
  const { register, handleSubmit, errors, watch } = useForm();
  const [isError, setIsError] = React.useState(false);
  const [errorText, setErrorText] = React.useState("");
  const [showSucces, setShowSucces] = React.useState(false);
  let history = useHistory();
  let query = useQuery();

  const watchAllFields = watch();
  const onSubmit = async (data) => {
    const toSend = {
      token: query.get("token"),
      password: data.password,
    };
    fetch(API.auth.resetPassword, {
      method: "POST",
      headers: { "Content-Type": "application/json", "x-site-id": sessionStorage.getItem('currentSite') ? JSON.parse(sessionStorage.getItem('currentSite'))._id : '', },
      body: JSON.stringify(toSend),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          setShowSucces(false);
          setIsError(true);
          if (response.message === "Invalide token") {
            setErrorText(resetPassword.errorServer);
          }
          setErrorText(resetPassword.errorServer);
        } else {
          setIsError(false);
          //setShowSucces(true);
          history.push("/login");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    /*
    fetch(API.auth.resetPassword, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(toSend)
    })
    .then(res => res.json)
    .then(response => {
      if(!response.ok) {
        setIsError(true);
        setErrorText(response.message);
      }else{
      setShowSucces(true);
      //    history.push('/login');

      }
    })
    .catch(err => console.log(err))  
  */
  };

  return (
    <LoginSection>
      <div className="container">
        <LoginContainer>
          <AuthDescription
            image={
              props.content.image ? STATIC.images(props.content.image) : ""
            }
          >
            <AuthDescriptionContent>
              <i className="flaticon-chili"></i>
              <h2>
                {props.content.titleImage ? props.content.titleImage : ""}
              </h2>
              <p>
                {props.content.subTitleImage ? props.content.subTitleImage : ""}
              </p>
            </AuthDescriptionContent>
          </AuthDescription>
          <AuthForm>
            <h2>{props.content.title ? props.content.title : ""}</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
              {isError ? (
                <div className="alert alert-danger" role="alert">
                  {errorText}
                </div>
              ) : (
                <div />
              )}
              {showSucces ? (
                <div className="alert alert-success" role="alert">
                  {resetPasswordMessage}{" "}
                </div>
              ) : (
                <div />
              )}
              {/*
                
                <div className="form-group">
                <input
                  type="text"
                  className="form-control form-control-light"
                  placeholder={ResetPasswordText.JETON_TEXT}
                  name="jeton"
                  ref={register({ required: true, message: formErrors.jetonRequired})}
                />
                <Error>
                {errors.jeton && errors.jeton.message}

                </Error>
              </div>
                
                
                */}

              <div className="form-group">
                <input
                  type="password"
                  className="form-control form-control-light"
                  placeholder={ResetPasswordText.PASSWORD_PLACEHOLDER}
                  name="password"
                  ref={register({
                    required: {
                      value: true,
                      message: formErrors.passwordRequired,
                    },
                  })}
                />
                <Error>{errors.password && errors.password.message}</Error>
              </div>
              <div className="form-group">
                <input
                  type="password"
                  className="form-control form-control-light"
                  placeholder={ResetPasswordText.CONFIRM_PASSWORD_PLACEHOLDER}
                  name="passwordRetyped"
                  ref={register({
                    validate: (value) => value === watchAllFields.password,
                    required: true,
                  })}
                />
                <Error>
                  {errors.passwordRetyped && formErrors.passwordIsNotIdentique}
                </Error>
              </div>
              <StyledButton className="login primary">
                {props.content.buttonContent ? props.content.buttonContent : ""}
              </StyledButton>
            </form>
          </AuthForm>
        </LoginContainer>
      </div>
    </LoginSection>
  );
};

export default ResetPassword;
