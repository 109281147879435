import styled from "styled-components";
const Container = styled.div`
  background-size: cover;
  background-position: center;
  min-height: 350px;
  padding: 220px 0 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  background-image: linear-gradient(
      ${(props) => props.theme.subHeader.bgImageLinearGradient},
      ${(props) => props.theme.subHeader.bgImageLinearGradient}
    ),
    url(${(props) => props.bgImage});

  h1 {
    font-size: 50px;
    line-height: 58px;
    color: ${(props) => props.theme.subHeader.color};
  }
  .subheader-inner .breadcrumb {
    background-color: transparent;
    padding: 0;
    margin: 0;
  }
  .subheader-inner .breadcrumb li,
  .subheader-inner .breadcrumb li a,
  .subheader-inner .breadcrumb li::before {
    color: ${(props) => props.theme.subHeader.subHeaderListBefore};
  }
`;
export { Container };
