import styled from "styled-components";
const ContactUsContainer = styled.div`
  display: flex;
  @media (max-width: 991px) {
    display: block;
  }
`;
const LinkButton = styled.a`
  cursor: pointer;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  position: relative;
  background-color: ${(props) => props.theme.customButton.bgColor};
  line-height: 24px;
  border: 0;
  color: ${(props) => props.theme.customButton.color};
  font-size: 14px;
  font-weight: 600;
  padding: 12px 30px;
  transition: 0.3s;
  text-transform: uppercase;
  border-radius: 25px;
  z-index: 1;
  box-shadow: 4px 3px 24px ${(props) => props.theme.customButton.boxShadow};

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.customButton.primaryBgColor};
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 25px;
    z-index: -1;
    transition: 0.3s;
    transform-origin: center;
    transform: scale(0);
  }

  &:hover::before,
  &:focus::before {
    transform: scale(1);
  }
  &:hover {
    text-decoration: none;
    color: ${(props) => props.theme.customButton.color};
    background-color: ${(props) => props.theme.customButton.primaryBgColor};
  }
  &:hover,
  &:focus {
    color: ${(props) => props.theme.customButton.color};
    outline: none;
  }
  &.primary {
    background-color: ${(props) => props.theme.customButton.primaryBgColor};
    box-shadow: 4px 3px 24px ${(props) => props.theme.customButton.boxShadow};
  }
  &.secondary {
    background-color: ${(props) => props.theme.customButton.secondaryBgColor};
    box-shadow: 0 3px 24px ${(props) => props.theme.customButton.boxShadow};
  }
  &.secondary::before {
    background-color: ${(props) =>
      props.theme.customButton.bgColorSecondaryBefore};
  }
  &.maps {
    position: absolute;
    bottom: 0px;
    left: 0;
    margin: 15px;
    z-index: 300;
  }
  &.btn-sm {
    padding: 8px 20px;
    font-size: 12px;
  }
`;
const MapsContainer = styled.div`
  position: relative;
  flex: 1;
  @media (max-width: 991px) {
    width: 100%;
    height: 60vh;
  }
`;
const ContactContainer = styled.div`
  flex: 1;
`;
const ContactSection = styled.div`
  padding: 80px 0 50px;
  padding-left: 40px;
  padding-right: 40px;
  @media (max-width: 991px) {
    padding: 60px 0 30px;
    padding-left: 0;
    padding-right: 0;
  }
`;
const ContactFormSection = styled.div`
  padding-bottom: 80px;
  padding-left: 40px;
  padding-right: 40px;
  @media (max-width: 991px) {
    padding-bottom: 60px;
    padding-left: 0;
    padding-right: 0;
  }
  input {
    height: 50px;
    padding: 8px 15px;
    border-radius: 8px;
    border: 1px solid
      ${(props) => props.theme.constactUs.formSection.input.borderColor};
    width: 100%;
    color: ${(props) => props.theme.constactUs.formSection.input.color};
    background-color: ${(props) =>
      props.theme.constactUs.formSection.input.backgroundColor};
    letter-spacing: 0.1px;
    font-size: 15px;
  }
`;
const InfoBox = styled.div`
  position: relative;
  overflow: hidden;
  background-color: ${(props) => props.theme.constactUs.infoBox.bgColor};
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 8px;
  z-index: 1;
  i {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 80px;
    line-height: 1;
    opacity: 0.2;
    z-index: -1;
  }
  h5 {
    font-size: 18px;
    margin: 0 0 20px;
    font-weight: 700;
    line-height: 28px;
    color: ${(props) => props.theme.constactUs.infoBox.titleColor};
  }
  span {
    display: block;
    font-weight: 600;
    margin-bottom: 5px;
  }
`;
const TitleSection = styled.div`
  h2 {
    font-size: 42px;
    margin-bottom: 20px;
    line-height: 48px;
  }
  p {
    max-width: 600px;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 28px;
    color: ${(props) => props.theme.constactUs.formSection.textColor};
  }
`;

const InformationSpan = styled.span`
  a {
    color: ${(props) => props.theme.constactUs.formSection.textColor};
    text-decoration: underline;
  }
  width: ${(props) => (props.width ? `${props.width}` : "100%")};
`;

export {
  ContactUsContainer,
  MapsContainer,
  ContactContainer,
  ContactSection,
  ContactFormSection,
  InfoBox,
  TitleSection,
  LinkButton,
  InformationSpan,
};
