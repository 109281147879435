import styled from "styled-components";
import React from "react";
const HowWeDoContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 60px 0;
`;
const TitleSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 10px;
  h5 {
    color: ${(props) => props.theme.HowWeDo.TitleSection.firstTitle.color};
    font-size: 18px;
    margin: 0 0 20px;
    font-weight: 700;
    line-height: 28px;
    text-align: center;
  }
  h2 {
    font-size: 32px;
    margin: 0 0 8px;
    font-weight: 700;
    color: ${(props) => props.theme.HowWeDo.TitleSection.secondTitle.color};
    margin-bottom: 10px;
    text-align: center;
  }
  p {
    text-align: center;
    margin: 0 auto 20px;
    color: ${(props) => props.theme.HowWeDo.TitleSection.text.color};
    max-width: 600px;
  }
`;
const bootsrapDiv = ({ className, children }) => (
  <div className={`row ${className}`}>{children}</div>
);

const InfographicsSection = styled(bootsrapDiv)`
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  margin-top: 20px;
  margin-bottom: 40px;
`;
const ButtonSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    background-color: ${(props) => props.theme.HowWeDo.ButtonSection.bgColor};
    color: ${(props) => props.theme.HowWeDo.ButtonSection.color};
    border-radius: 25px;
    padding: 12px 30px;
    font-size: 14px;
    cursor: pointer;
    font-weight: 600;
    box-shadow: 4px 3px 24px
      ${(props) => props.theme.HowWeDo.ButtonSection.boxShaddaw};
    text-decoration: none;
  }
`;
export { HowWeDoContainer, TitleSection, InfographicsSection, ButtonSection };
