import React, { useState, useEffect } from "react";

import Checkout from "../../components/Checkout/index";
import { connect } from "react-redux";
import { setSubHeader } from "../../store/actions/stickyNavbarAction";
import { withRouter, useLocation } from "react-router-dom";
import { destroyCart } from "../../store/actions/cartActions";
import { saveOrder } from "../../store/actions/orderActions";
import { SubHeaderText } from "../../constants/texts";
import { images } from "../../constants/env";
import { API, STATIC } from "../../constants/env";
import { getProducts, getTotal } from "../../store/Selectors";
import { getToken } from "../../services/LoadToken";
const CheckoutPage = (props) => {
  const [data, setData] = useState([]);
  const [isLoding, setIsLoding] = useState(true);
  const menus = [
    { link: "/", title: SubHeaderText.HOME },
    { link: "/checkout", title: SubHeaderText.checkout.TITLE },
  ];
  const [content, setContent] = useState(
    localStorage.getItem("CHECKOUT")
      ? JSON.parse(localStorage.getItem("CHECKOUT"))
      : {}
  );

  useEffect(() => {
    const fetchData = () => {
      fetch(API.content.load, {
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
          "x-site-id": sessionStorage.getItem('currentSite') ? JSON.parse(sessionStorage.getItem('currentSite'))._id : '',
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: "CHECKOUT",
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          if (JSON.stringify(response) !== localStorage.getItem("CHECKOUT")) {
            localStorage.setItem("CHECKOUT", JSON.stringify(response));
            setContent(response);
          }
        })
        .catch((e) => {});
    };
    fetchData();
  }, []);
  useEffect(() => {
    props.setSubHeader(
      true,
      content.title,
      STATIC.images(content.subHeaderImage),
      menus
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  useEffect(() => {
    const fetchData = async () => {
      const token = getToken();
      if (token) {
        try {
          const response = await fetch(API.me.getUser, {
            headers: {
              "x-auth-token": token,
              "x-site-id": sessionStorage.getItem('currentSite') ? JSON.parse(sessionStorage.getItem('currentSite'))._id : '',
            },
          });
          const data = await response.json();
          setIsLoding(!isLoding);
          setData(data);
        } catch (e) {}
      } else {
      }
    };
    if (props.isAutentificated) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isAutentificated]);

  //const { remarque } = props.location.state
  let location = useLocation();

  const handleDestoyCart = () => {
    props.destroyCart();
  };
  return (
    <div>
      <Checkout
        items={props.items}
        total={props.total}
        dileveryPrice={props.dileveryPrice}
        remarque={location.state ? location.state.remarque : ""}
        destroyCart={handleDestoyCart}
        user={data}
        isAutentificated={props.isAutentificated}
        saveOrder={props.saveOrder}
      />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    //items: state.cartReducer.addedItems,
    //total: state.cartReducer.total,
    items: getProducts(state),
    total: getTotal(state).toFixed(2),
    dileveryPrice: state.cartReducer.dileveryPrice
      ? state.cartReducer.dileveryPrice
      : 0,
    isAutentificated: state.authReducer.isAutentificated,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setSubHeader: (show, title, bgImage, menus) => {
      dispatch(setSubHeader(show, title, bgImage, menus));
    },
    destroyCart: () => {
      dispatch(destroyCart());
    },
    saveOrder: order => {
      dispatch(saveOrder(order));
    }
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CheckoutPage));
