/* eslint-disable no-unused-vars */
import React, { useCallback, useRef } from "react";
import Swiper from "react-id-swiper";
import {
  MenuCategories,
  CategoryItem,
  CategoryImage,
  CategoryDescription,
  ConatinerCategories,
  NavigationButton,
  ListProducts,
  ProductContainer,
  FilterContainer,
} from "./style";
import "swiper/css/swiper.css";
import Product from "../Product/index";
import { API, STATIC } from "../../constants/env";
const Menu = (props) => {
  const [swiper, updateSwiper] = React.useState(null);
  const [currentItem, setCurrentItem] = React.useState(0);
  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  };
  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev();
    }
  };
  const params = {
    slidesPerView: props.categories && props.categories.length < 5 ?  props.categories.length : 5,
    spaceBetween: 40,
    pagination: false,
    breakpoints: {
      1024: {
        slidesPerView: props.categories && props.categories.length < 5 ?  props.categories.length : 5,
        spaceBetween: 40,
      },
      768: {
        slidesPerView: props.categories && props.categories.length < 4 ?  props.categories.length : 4,
        spaceBetween: 40,
      },
      640: {
        slidesPerView: props.categories && props.categories.length < 3 ?  props.categories.length : 3,
        spaceBetween: 30,
      },
      320: {
        slidesPerView: props.categories && props.categories.length < 2 ?  props.categories.length : 2,
        spaceBetween: 20,
      },
    },
  };
  return (
    <>
      <MenuCategories>
        <div className="container">
          <ConatinerCategories>
            <NavigationButton onClick={goPrev}>
              <i className="fas fa-arrow-left"></i>
            </NavigationButton>

            <Swiper getSwiper={updateSwiper} {...params} shouldSwiperUpdate>
              {props.categories.map((elem, index) => (
                <div key={elem._id}>
                  <CategoryItem
                    onClick={() => {
                      props.selectCategory(elem._id);
                      setCurrentItem(index);
                    }}
                    className={currentItem === index ? "active-item" : ""}
                  >
                    <CategoryImage>
                      <img src={elem.picture} alt="category" />
                    </CategoryImage>
                    <CategoryDescription>
                      <h6>{elem.name}</h6>
                    </CategoryDescription>
                  </CategoryItem>
                </div>
              ))}
            </Swiper>

            <NavigationButton onClick={goNext}>
              <i className="fas fa-arrow-right"></i>
            </NavigationButton>
          </ConatinerCategories>
        </div>
      </MenuCategories>
      <FilterContainer>
        <label>trier par: </label>

        <div className="form-group">
          <select
            className="form-control"
            onChange={(e) => props.handleChange(e)}
            default=""
          >
            <option value="-" default>
              -
            </option>
            <option value="asc">Prix croissant</option>
            <option value="desc">Prix decroissant</option>
          </select>
        </div>
      </FilterContainer>
      <ListProducts>
        <div className="container">
          <div className="row">
            {console.log(`${STATIC.images(props.productsToShow[0].picture)}`)}
            {props.productsToShow.map((elem) => (
              <ProductContainer className="col-lg-4 col-md-6" key={elem._id}>
                <Product
                  storeInformation={props.storeInformation}
                  item={elem}
                  title={elem.name}
                  img={STATIC.images(elem.picture)}
                  text={elem.description}
                  price={elem.price}
                  add={props.addItemToCart}
                  handleShowOptions={props.handleShowOptions}
                />
              </ProductContainer>
            ))}
          </div>
        </div>
      </ListProducts>
    </>
  );
};

export default Menu;
/*

*/
