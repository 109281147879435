import {
    FETCH_SITES
} from '../actions/action-types/sites-action';
import {STATIC} from '../../constants/env';
const intialState = {sitesList: []}
const sitesReducer = (
    state = intialState,
    action
) => {
    if (action.type === FETCH_SITES) {
        return {
            ...state,
            sitesList: action.payload.sites,
        }
    }
    return {...state}
};

export default sitesReducer;