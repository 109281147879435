import React from "react";
import {
  CartItemContainer,
  MediaCartItem,
  DetailsCartItem,
  ImageContainer,
} from "./style";
import { CloseButton } from "../Modal/style";
import { Link } from "react-router-dom";
import { STATIC } from "../../../constants/env";
const CartItem = (props) => {
  const handleRemove = (item) => {
    props.removeItem(item);
  };
  let items = [];
  let total = 0;
  if (props.item.selectedOptions && props.item.selectedOptions.length > 0) {
    props.item.selectedOptions.forEach((option) => {
      option.items.forEach((elem) => {
        items.push(elem.name);
        items.push(", ");
        total += elem.price;
      });
    });
  }
  
  if (props.item.selectedSupplements && props.item.selectedSupplements.length > 0) {
    props.item.selectedSupplements.forEach((supliment) => {
      supliment.items.forEach((elem) => {
        items.push(elem.name);
        items.push(", ");
        total += elem.price;
      });
    });
  }
  
  items.pop();

  return (
    <CartItemContainer>
      <MediaCartItem>
        <ImageContainer
          style={{ backgroundImage: `url(${STATIC.images(props.picture)})` }}
        />

        <div>
          <h5>
            <Link
              to={`/description/${props.item._id}`}
              onClick={props.closeModal}
            >
              {props.name}
            </Link>
          </h5>
          <span>
            {props.quantity} * {Number(props.price).toFixed(2)}€
          </span>
          <span>{items}</span>
          <span>{props.item.instructions}</span>
        </div>
      </MediaCartItem>
      <DetailsCartItem>
        <div>{Number((props.price + total) * props.quantity).toFixed(2)}€</div>
        <CloseButton
          className="details-cart-item"
          onClick={() => {
            handleRemove(props.index);
          }}
        >
          <span></span>
          <span></span>
        </CloseButton>
      </DetailsCartItem>
    </CartItemContainer>
  );
};

export default CartItem;
