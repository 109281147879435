exports.TopHeaderText = {
  BUTTON_TEXT: "Cliquez ici pour commander",
  PHONE_NUMBER: "06.13.97.72.43",
  SEE_CATALOG: "Consulter notre catalogue"
};

exports.Bannerv1 = {
  slide1: {
    TITLE: "Bienvenue sur votre futur site de Click & Collect",
    SUBTITLE: "Utilisation facile pour vos clients, Paramétrage facile pour vous",
    PARAGRAPH:
      "Passez des commandes de tests pour vous rendre compte de la simplicité",
    BUTTON_TEXT: "Boutique en ligne",
  },
  slide2: {
    TITLE: "Célébrer 100 ans de pizza au fromage",
    SUBTITLE:
      "Rejoignez notre grande ouverture ce soir pour une pizza gratuite",
    PARAGRAPH:
      "Rejoignez nous ce soir et voul$s allez avoir une pizza gratuite. Si vous amenez un ami vous allez avoir des ticket pour deux pizza gratuite pendant une semaine",
    BUTTON_TEXT: "Voir tout",
  },
};
exports.BannerV2 = {
  BUTTON_TEXT: "Commander",
};
exports.HowWeDeliverText = {
  TitleSection: {
    TITLE: "Notre fonctionnement",
    STEPS: "Nous gérons vos commandes en 4 étapes",
    CONTENT:
      "Le site de commande en ligne vous permet de passer commande du Lundi au Dimanche. Le paiement se fait soit en ligne soit à la livraison, par CB, Espèces ou Titres restaurant. Pour toute demande spécifique, nous sommes disponibles par mail et téléphone.",
  },
  InfographicItems: {
    ONE: {
      icon: "flaticon-online-booking",
      title: "Commande",
      text:
        "Passez commande, nous les réceptionnons au restaurant. Vous pouvez nous contacter à tout moment en cas d’erreur.",
    },
    TWO: {
      icon: "flaticon-calories",
      title: "Préparation",
      text:
        "Vos commandes sont préparées dans le plus strict respect des règles d’hygiène, blouse, gant, masque, charlotte.",
    },
    THREE: {
      icon: "flaticon-delivery-man",
      title: "Livraison ",
      text:
        "Les livraisons commencent à 12h15 et couvrent une zone de 5km autour du restaurant.Pour toute commande inférieure à 40€, 1€ de frais de livraison sera appliquée à la commande",
    },
    FOUR: {
      icon: "flaticon-food-tray",
      title: "Dégustez",
      text: "Profitez de nos plats fait maison et bonne dégustation",
    },
  },
  BUTTON_TEXT: "Cliquez ici pour commander",
};
exports.CartModalText = {
  HEADER_TITLE: "Panier",
  FOOTER_TOTAL: "Total: ",
  BUTTON_TEXT: "Commander",
  NOTHING_TEXT: "Aucun produit",
};
/*
exports.TrendingText={
    SUBTITLE:'Trending',
    TITLE: "Our Customers' Top Picks",
    PARAGRAPH:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy",
    PRODUCT_BUTTON:'Order'
}
*/
exports.TrendingText = {
  SUBTITLE: "",
  TITLE: "Menu du jour",
  PARAGRAPH: "",
  PRODUCT_BUTTON: "Commander",
  CUSTOMIZE_BUTTON: "Personnaliser",
};
exports.JoinNewsLetter = {
  TITLE: "Rejoignez votre NewsLetter",
  TEXT:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
  PLACEHOLDER: "Saisissez votre adresse mail",
  BUTTON_TEXT: "Envoyer",
};
exports.FooterText = {
  information: {
    TITLE: "Information",
    FIRST_LIST: "Accueil",
    SECOND_LIST: "A propos",
    THIRD_LIST: "Contact",
  },
  topItems: {
    TITLE: "Suggestion",
    FIRST_LIST: "Perperoni",
    SECOND_LIST: "Swiss Mushroom",
    THIRD_LIST: "Vegetarian",
  },
  others: {
    TITLE: "Autres",
    FIRST_LIST: "Panier",
    SECOND_LIST: "Carte",
    THIRD_LIST: "Menu",
    PARAGRAPH_TEXT:
      "Inscrivez-vous et obtenez des offres exclusives et des codes de réduction",
    BUTTON_TEXT: "Inscription",
    LOGIN_BUTTON_TEXT: "Connexion",
  },
  socialMedia: {
    TITLE: "Réseaux sociaux",
  },
  footerBottom: "© 2020 iorder.fr - Crafted with love by aurax.io",
};

exports.SubHeaderText = {
  HOME: 'Accueil',
  products: {
    TITLE: "Notre Carte",
  },
  cart: {
    TITLE: "Panier",
  },
  checkout: {
    TITLE: "Commande",
  },
  about: {
    TITLE: "A propos",
  },
  contact: {
    TITLE: "Contact",
  },
  description: {
    TITLE: "Description",
  },
};
exports.TableText = {
  cartTable: {
    PRODUCT: "Produit",
    PRICE: "Prix",
    QUANTITE: "Quantité",
    TOTAL: "Total",
  },
  cartForm: {
    TITLE: "Total Panier",
    SUBTOTAL: "Sous Total",
    TAX: "TVA",
    TOTAL: "Total",
  },
};
exports.CheckoutText = {
  form: {
    TITLE: "Informations sur la commande",
    FIRST_NAME_LABEL: "Nom",
    FIRST_NAME_PLACEHOLDER: "Nom",
    LAST_NAME_LABEL: "Prénom",
    LAST_NAME_PLACEHOLDER: "Prénom",
    PHONE_LABEL: "Téléphone ",
    PHONE_PLACEHOLDER: "Téléphone",
    EMAIL_LABEL: "Adresse email",
    EMAIL_PLACEHOLDER: "Adresse email",
    ADRESS_LABEL: "Adresse",
    ADRESS_PLACEHOLDER: "Adresse",
    ADRESS2_LABEL: "Adresse 2",
    ADRESS2_PLACEHOLDER: "Adresse 2",
    VILLE_LABEL: "Ville",
    VILLE_PLACEHOLDER: "Ville",
    POSTAL_CODE_LABEL: "Code Postal",
    POSTAL_CODE_PLACEHOLDER: "Code Postal",
    JUSTIFICATION_LABEL: "justificatif sans détail",
    PAIEMENT_MODE: "Mode de paiement",
    PAIEMENT_MODE_CHOICE1: "Espèces",
    PAIEMENT_MODE_CHOICE2: "Carte bancaire",
    PAIEMENT_MODE_CHOICE3: "Titres restaurant",
    PAIEMENT_MODE_CHOICE4: "Paiement en ligne",
    IS_DILIVERY_TEXT: "Livraison (décocher si emporter)",
    NOT_DILVERY_TEXT: "Emporter",
    PASSAGE_TIME_TEXT: "Veuillez choisir l'heure de passage",
    LIVRAISON_TIME_TEXT: "Veuillez choisir l'heure de livraison",
    PASSAGE_DATE_TEXT: "Veuillez choisir la date de passage",
    LIVRAISON_DATE_TEXT: "Veuillez choisir la date de livraison",
    BUTTON_TEXT: "Valider",
    REFERENCE_COMMANDE: 'Référence Commande',
    REFERENCE_COMMANDE_PH: 'Veuillez saisir la référence de la commande',
    MIN_COMMANDE: (minCommande) => `Vous devez avoir une commande avec un minimum de ${minCommande}€`
  },
  noticeLogin: {
    PARAGRAPH_TEXT: "Vous êtes déjà client ? ",
    BUTTON_TEXT: "Connectez-vous",
    EMAIL_LABEL: "Adresse email",
    EMAIL_PLACEHOLDER: "Adresse email",
    PASSWORD_LABEL: "Mot de passe",
    PASSWORD_PLACEHOLDER: "Mot de passe",
    REMEMBER_ME_LABEL: "Rester connecter",
  },
  orderDetails: {
    PRODUCT_TEXT: "Produit",
    QUANTITY_TEXT: "Quantité",
    TOTAL_TEXT: "Total",
    DELIVERY_TEXT: "Frais livraison",
    BIG_TOTAL: "Total général",
    PRICE_AND_QUANTITY_TEXT: "Prix",
    FREE: "Gratuit",
  },
};
exports.LoginText = {
  WELCOME_TEXT: "Bienvenue!",
  PARAGRAPH_TEXT:
    "Connectez vous en quelques clics ! Connectez vous et passez votre commande",
  TITLE: "se connecter",
  USERNAME_PLACEHOLDER: "Adresse email",
  PASSWORD_PLACEHOLDER: "Mot de passe",
  FORGOT_PASSWORD: "Mot de passe oublié?",
  LOGIN_BUTTON: "Se connecter",
  DONT_HAVE_ACCOUNT: "Vous n'avez pas de compte?",
  CREATE_ACCOUNT: "Creer un compte",
  REMEMBER_ME_LABEL: "Rester connecté",
};
exports.RegisterText = {
  WELCOME_TEXT: "Bienvenue!",
  PARAGRAPH_TEXT:
    "Créez votre compte client en quelques clics ! Inscrivez vous et passez votre commande",
  TITLE: "créer un compte",
  FIRST_NAME_PLACEHOLDER: "Nom",
  LAST_NAME_PLACEHOLDER: "Prenom",
  PHONE_PLACEHOLDER: "Teléphone",
  EMAIL_PLACEHOLDER: "Adresse email",
  PASSWORD_PLACEHOLDER: "Mot de passe",
  REGISTER_BUTTON: "Créer un compte",
  HAVE_ACCOUNT: "Vous avez déjà un compte?",
  LOGIN: "Se connecter",
};

exports.MyAccountText = {
  WELCOME_TEXT: "Bienvenue",
  sideBarMenu: {
    MY_PROFIL_TEXT: "Mon Profil",
    MY_ADRESS_TEXT: "Mon Adresse",
    MY_ORDERS_TEXT: "Mes Commandes",
    LOGOUT_TEXT: "Déconnexion",
  },
  myAdress: {
    ADRESSE1_TEXT: "Adresse 1",
    ADRESSE2_TEXT: "Adresse 2",
    VILLE_TEXT: "Ville",
    ZIP_CODE: "Code Postal",
  },
  myOrder: {
    REFERENCE_TEXT: "Réf",
    DATE_TEXT: "Date",
    PRODUCT_TEXT: "Produits",
    TOTAL_TEXT: "Total",
    SHOW_MORE_TITLE_TEXT: "Détails",
    BL: 'Bl PDF',
    SHOW_MORE_TEXT: "Afficher plus",
  },
  myProfil: {
    ENSEIGNE_TEXT: "Enseigne",
    FIRST_NAME_TEXT: "Nom",
    LAST_NAME_TEXT: "Prénom",
    EMAIL_TEXT: "Adresse email",
    PHONE_TEXT: "Téléphone",
    PASSWORD_TEXT: "Mot de passe Actuel",
    NEW_PASSWORD_TEXT: "Nouveau Mot de passe",
    CONFIRM_PASSWORD_TEXT: "Confirmer le Nouveau Mot de passe",
    ACTUAL_PASSWORD: "Mot de passe actuel",
  },
  orderDetails: {
    REFERENCE: "Référence",
    COMMANDE_TEXT: "Commande",
    PAYEMENT_MODE: "Mode de paiement",
    DELIVERY_DATE: "Date de livraison",
    COMMANDE_DATE: "Date de commande",
    PRODUCT_LIST: "Liste des produits",
  },
  BUTTON_TEXT: "Mettre à jour",
};
exports.ForgetPasswordText = {
  WELCOME_TEXT: "Mot de passe oublié?",
  PARAGRAPH_TEXT: "Réinitialisez votre mot de passe en quelques clics.",
  TITLE: "Réinitialiser Mot de passe",
  USERNAME_PLACEHOLDER: "Adresse email",
  LOGIN_BUTTON: "Réinitialiser Mot de passe",
};
exports.ResetPasswordText = {
  WELCOME_TEXT: "Réinitialiser votre mot de passe",
  PARAGRAPH_TEXT: "Réinitialisez votre mot de passe en quelques clics",
  TITLE: "Réinitialiser Mot de passe",
  USERNAME_PLACEHOLDER: "Adresse email",
  PASSWORD_PLACEHOLDER: "Mot de passe",
  CONFIRM_PASSWORD_PLACEHOLDER: "Confirmez le mot de passe",
  LOGIN_BUTTON: "Réinitialiser Mot de passe",
  JETON_TEXT: "Jeton",
};
exports.AboutText = {
  TITLE_TEXT: "A propos",
  SUBTITLE_TEXT: "Qui sommes nous?",
  PARAGRAPH1_TEXT:
    "Venez découvrir Iorder, ses grillades et plats du jour fait maison ,sa cave de maturation avec ses cotes de bœuf maturées quatre semaines. une terrasse à l'abri du soleil avec des brumisateur, un cadre chaleureux avec ses 2 terrasses. Un parking de 90 places surveillé par un agent de sécurité",
  PARAGRAPH2_TEXT:
    "En plus du restaurant, de la livraison et des plats à emporter nous proposons un service traiteur pour vos repas et dîners. Sur place nous pouvons organiser anniversaires, départs à la retraite, événements d'entreprise... , le week-end et en soirée la semaine.",
  BUTTON_TEXT: "Consultez notre menu",
};
exports.DescriptionText = {
  SUGGESTION_TITLE: "Vous pourriez aussi aimer",
  QUANTITY_TEXT: "Quantité",
  CATEGORY_TEXT: "Catégorie",
  BUTTON_TEXT: "Commander ",
};
exports.ContactUsText = {
  FIND_US_TEXT: "Trouvez nous",
  ADRESSE1_TEXT: "18 Chemin de Mimet",
  ADRESSE2_TEXT: "13015 MARSEILLE",
  PHONE_NUMBER_TEXT: "06.13.97.72.43",
  MAIL_ADRESSE_TEXT: "olivier@klikx.lol",
  OPENING_HOUR_TEXT: "Horaires d'ouvertures",
  DAY1_TEXT: "Lun - Vend:",
  TIME_DAY1_TEXT: " 08h00 - 20h:00",
  DAY2_TEXT: "Sam - Dim:",
  TIME_DAY2_TEXT: " 08h:00 - 22h:00",
  FORM_TITLE: "Envoyez-nous un message",
  PARAGRAPH_DESCRIPTION:
    "Envoyez-nous votre requête via le formulaire en ligne ci-contre et nous vous répondrons sous 2 jours ouvrables. ",
  FIRST_NAME_PALCEHOLDER: "Nom",
  LAST_NAME_PLACEHOLDER: "Prénom",
  EMAIL_PLACEHOLDER: "Adresse email",
  SUBJECT_PLACEHOLDER: "Sujet",
  MESSAGE_PLACEHOLDER: "Message",
  BUTTON_TEXT: "envoyer",
  WEEKSDAY: 'Lundi_Mardi_Mercredi_Jeudi_Vendredi_Samedi_Dimanche'
};
exports.NotFoundText = {
  TITLE_TEXT: "Page non trouvée",
  PARAGRAPH_TEXT: "Désolé, la page que vous recherchez n'existe pas ",
  BUTTON_TEXT: "Retourner à l'accueil",
};

exports.PaymentSucceedText = {
  TITLE_TEXT: "Commande Reçue",
  CONTENT_TEXT:
    "Nous sommes ravis de vous informer que nous avons reçu votre commande",
  BUTTON_TEXT: "Retour à l'accueil",
};
exports.PayementFail = {
  TITLE_TEXT: "Echec de paiement",
  CONTENT_TEXT: "Désolé, votre paiement a échoué",
  BUTTON_TEXT: "Retourner à l'accueil",
};

exports.ProductOptionsText = {
  REQUIRED_TEXT: "Obligatoire",
  SPECIAL_INSTRUCTIONS_TEXT: "Instructions spéciales",
  SPECIAL_INSTRUCTIONS_PARAGRAPH: "Instructions spéciales",
  SPECIAL_INSTRUCTIONS_PLACEHOLDER: "Tapez votre texte ici",
  CANCEL_BUTTON_TEXT: "Annuler",
  ADD_BUTTON_TEXT: "Ajouter au panier",
};
exports.NotFoundTextPage = {
  TITLE_TEXT: "Page non trouvée",
  CONTENT_TEXT: "Désolé, la page que vous recherchez n'existe pas",
  BUTTON_TEXT: "Retourner à l'accueil",
};

exports.mapsModal = {
    title: "Confirmez votre adresse",
    description: "Drag the pin to your exact location",
    buttonConfirm : "confirmer",
}
exports.profileImageModal = {
  title: "Uplaud image",
  buttonConfirm: "confirmer",

}