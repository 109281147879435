import styled from "styled-components";

const AccountContainer = styled.div`
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  margin-left: 20px;
  @media (max-width: 768px) {
    margin: 0px;
  }
`;

const ProfileImageOuter = styled.div`
  box-sizing: border-box;
  max-width: auto;
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  input {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    z-index: 3;
    cursor: pointer;
  }
`;

const ProfileImageInner = styled.div`
  border-radius: 50%;
  padding: 5px;
  background-color: ${(props) => props.theme.account.profileImgInnerBgColor};
  box-sizing: border-box;
  cursor: pointer;
  img {
    height: 200px;
    width: 200px;
    border-radius: 50%;
    border: 5px solid white;
    vertical-align: middle;
  }
  span {
    position: absolute;
    z-index: 2;
    width: 40px;
    height: 40px;
    bottom: 15px;
    right: 15px;
    height: 40px;
    background-color: ${(props) => props.theme.account.iconBgColor};
    text-align: center;
    border-radius: 50%;
    line-height: 38px;
  }
  span i {
    color: ${(props) => props.theme.account.iconColor};
  }
`;
const Sidebar = styled.aside`
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    padding: 25px 0;
  }
  ul li {
    line-height: 24px;
    padding-top: 7px;
    border-bottom: 1px solid
      ${(props) => props.theme.account.sideBarBorderColor};
  }
  ul li a {
    color: ${(props) => props.theme.account.listColor};
    font-size: 0.9rem;
    padding: 8px 0 8px 0px;
    text-decoration: none;
    line-height: 20px;
    cursor: pointer;
  }
  a:hover {
    cursor: pointer;
  }
  ul li a:focus {
    color: ${(props) => props.theme.account.listFocusColor};
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
  }

  ul li a:before {
    font-family: "Font Awesome 5 Free";
    content: "\f054";
    font-weight: 900;
    font-size: 0.45rem;
    opacity: 0.7;
  }
`;

const Welcome = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
`;
export {
  AccountContainer,
  Sidebar,
  ProfileImageOuter,
  ProfileImageInner,
  Welcome,
};
