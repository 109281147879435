import styled from "styled-components";

const ProductContainer = styled.div`
  box-shadow: 0 3px 12px 0 ${(props) => props.theme.product.boxShaddaw};
  background-color: ${(props) => props.theme.product.bgColor};
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: auto;
  margin: 0;
  a {
    text-align: center;
  }
`;
const ProductBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 20px 20px 20px;
  text-align: center;
`;
const ProductDescription = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  h4 {
    margin-bottom: 15px;
    font-weight: 700;
  }
  h4 a {
    text-decoration: none;
    color: ${(props) => props.theme.product.productTitleDescriptionColor};
  }
  a:hover {
    color: ${(props) => props.theme.globalStyle.linkHoverColor};
  }
`;
const DescriptionContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  p {
    margin-bottom: 0px;
    font-size: 15px;
    line-height: 20px;
    color: ${(props) => props.theme.product.productDescriptionTextColor};
    text-align: center;
  }
`;
const Price = styled.div`
  display: inline-block;
  font-weight: 700;
  color: ${(props) => props.theme.product.productDescriptionPriceColor};
  padding: 5px 15px;
  border-radius: 25px;
  border: 1px solid
    ${(props) => props.theme.product.productDescriptionPriceBorderColor};
`;
const ProductControls = styled.div`
  margin-top: auto;
  width: 100%;
  border-top: 1px solid
    ${(props) => props.theme.product.productBodyBottomBorderColor};
  padding-top: 20px;
  border-top-width: 90%;
  display: flex;
  justify-content: space-between;
`;

const ImageContainer = styled.div`
  width: 100%;
  min-height: 250px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;
export {
  ProductContainer,
  ProductBody,
  ProductDescription,
  ProductControls,
  ImageContainer,
  DescriptionContent,
  Price,
};
