import React, { useState } from 'react'
import { CheckoutContainer } from './style';
import OrderDetails from './OderDetails/index';
import BillingsForm from './BillingsForm/index';
import NoticeLogin from './NoticeLogin/index';
import { API } from "../../constants/env";

const Checkout = (props) => {
    const [selectedDelivery, setSelectedDelivery] = useState(true);
    const [deliveryPrice, setDeliveryPrice] = useState(0);
    const [minCommande, setMinCommande] = useState(0);

    React.useEffect(() => {
        const fetchData = async () => {
          const response = await fetch(API.settings.load, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "x-site-id": sessionStorage.getItem('currentSite') ? JSON.parse(sessionStorage.getItem('currentSite'))._id : '',
            },
            body: JSON.stringify({
              key: "DELIVERY",
            }),
          })
            .then((res) => res.json())
            .then((response) => {
              
              if (response.minCommande && response.minCommande > 0) {
                setMinCommande(response.minCommande);
              }
            })
            .catch((e) => console.log(e));
        };
        fetchData();
      }, []);
    return (
        <CheckoutContainer>
            <div className="container">
                <div className="row">
                    <div className="col-xl-7">
                        <NoticeLogin
                        />
                        <BillingsForm
                            saveOrder={props.saveOrder}
                            products={props.items}
                            remarque={props.remarque}
                            destroyCart={props.destroyCart}
                            user={props.user}
                            selectedDelivery={selectedDelivery}
                            setSelectedDelivery={setSelectedDelivery}
                            setDeliveryPrice={setDeliveryPrice}
                            total={props.total}
                        />
                    </div>
                    <div className="col-xl-5">
                        <OrderDetails
                            items={props.items}
                            total={props.total}
                            selectedDelivery={selectedDelivery && props.total > minCommande}
                            deliveryPrice={deliveryPrice}
                        />
                    </div>
                </div>
            </div>
        </CheckoutContainer>
    )
}

export default Checkout
