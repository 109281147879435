import React from "react";
import PayementSucceed from "../../components/PaymentSucceed/index";
function PaymentSuccedPage(props) {
  return (
    <div>
      <PayementSucceed/>
    </div>
  );
}

export default PaymentSuccedPage;
