import {SAVE_ORDER, GET_ORDER} from './action-types/order-actions';
export const saveOrder = (order) => {
  return {
    type: SAVE_ORDER,
    payload: {
        order,
    },
  };
};
export const getOrder = () => {
  return {
    type: GET_ORDER,
  }
}