import React, { useState } from "react";
import { MyProfilContainer } from "./style";
import { StyledButton } from "../../CustomButton/style";
import { MyAccountText } from "../../../constants/texts";
import { useForm } from "react-hook-form";
import { formErrors } from "../../../constants/errors";
import { updateInfoMessage } from "../../../constants/succesMessage";
import { Error } from "../../FormError/style";
import { API } from "../../../constants/env";
import Password from "../Password/index";
import { getToken } from "../../../services/LoadToken";
import PuffLoader from "react-spinners/PuffLoader";
import { LoaderPuff } from "../../Checkout/BillingsForm/style";

const MyProfil = (props) => {
  const { register, handleSubmit, errors, getValues } = useForm();
  const [isError, setIsError] = useState(false);
  const [errorServer, setErrorServer] = useState("");
  const [showSucces, setShowSucces] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingButton, setIsLoadingButton] = useState(false);

  const [buttonWidth, setButtonWidth] = useState("");
  const [user, setUser] = useState({});
  React.useEffect(() => {
    const width = document.getElementById("update").offsetWidth;
    setButtonWidth(width + "px");
  }, []);
  React.useEffect(() => {
    const fetchData = async () => {
      const token = getToken();

      if (token) {
        try {
          const response = await fetch(API.me.getUser, {
            headers: {
              "x-auth-token": token,
              "x-site-id": sessionStorage.getItem('currentSite') ? JSON.parse(sessionStorage.getItem('currentSite'))._id : '',
            },
          });
          const data = await response.json();
          setUser(data);
          setIsLoading(false);
        } catch (e) {}
      } else {
      }
    };
    fetchData();
  }, []);

  const onSubmit = (data) => {
    setIsLoadingButton(true);
    const token = getToken();
    if (token) {
      const formData = new FormData();

      formData.append("user", props.user);
      formData.append("firstName", data.fname);
      formData.append("lastName", data.lname);
      formData.append("phone", data.phone);
      formData.append("email", data.email);
      formData.append("enseigne", data.enseigne)
      if (props.file !== null) {
        formData.append("file", props.file, props.file.name);
      }

      fetch(API.me.update, {
        method: "POST",
        headers: {
          "x-auth-token": token,
          "x-site-id": sessionStorage.getItem('currentSite') ? JSON.parse(sessionStorage.getItem('currentSite'))._id : '',
        },
        body: formData,
      })
        .then((response) => response.json)
        .then((response) => {
          if (response.error) {
            setErrorServer("registerFormErrors.failRegister");
            setIsError(true);
            setIsLoadingButton(false);
          } else {
            setShowSucces(true);
            setIsLoadingButton(false);
          }
        })
        .catch((err) => {
          setIsLoadingButton(false);
        });
    }
  };
  return (
    <>
      <MyProfilContainer className="container">
        <h2>Mon Profil</h2>
        <div>
          {isLoading ? (
            <LoaderPuff>
              <PuffLoader size={60} color={"#bbd047"} loading={isLoading} />
            </LoaderPuff>
          ) : (
            <form
              onSubmit={handleSubmit(onSubmit)}
              onKeyPress={(e) => {
                if (e.key == "Enter") {
                  e.preventDefault();
                }
              }}
            >
              <div className="form-group row">
                <label className="col-lg-3 font-weight-bold text-dark col-form-label form-control-label text-2 required">
                  {MyAccountText.myProfil.FIRST_NAME_TEXT}
                </label>
                <div className="col-lg-9">
                  <input
                    name="fname"
                    className="form-control"
                    type="text"
                    defaultValue={user.firstName}
                    ref={register({ required: true })}
                  />
                  <Error>{errors.fname && formErrors.fnameRequired}</Error>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-lg-3 font-weight-bold text-dark col-form-label form-control-label text-2 required">
                  {MyAccountText.myProfil.LAST_NAME_TEXT}
                </label>
                <div className="col-lg-9">
                  <input
                    name="lname"
                    className="form-control"
                    type="text"
                    defaultValue={user.lastName}
                    ref={register({ required: true })}
                  />
                  <Error>{errors.lname && formErrors.lnameRequired}</Error>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-lg-3 font-weight-bold text-dark col-form-label form-control-label text-2 required">
                  {MyAccountText.myProfil.ENSEIGNE_TEXT}
                </label>
                <div className="col-lg-9">
                  <input
                    name="enseigne"
                    className="form-control"
                    type="text"
                    defaultValue={user.enseigne}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-lg-3 font-weight-bold text-dark col-form-label form-control-label text-2 required">
                  {" "}
                  {MyAccountText.myProfil.EMAIL_TEXT}
                </label>
                <div className="col-lg-9">
                  <input
                    disabled
                    name="email"
                    className="form-control"
                    type="text"
                    defaultValue={user.email}
                    ref={register({
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: formErrors.mailAdressValidity,
                      },
                    })}
                  />
                  <Error>{errors.email && errors.email.message}</Error>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-lg-3 font-weight-bold text-dark col-form-label form-control-label text-2 required">
                  {MyAccountText.myProfil.PHONE_TEXT}
                </label>
                <div className="col-lg-9">
                  <input
                    disabled
                    name="phone"
                    className="form-control"
                    type="tel"
                    defaultValue={user.phone}
                    ref={register({ required: true })}
                  />
                  <Error>{errors.phone && formErrors.phoneRequired}</Error>
                </div>
              </div>
              {isError ? (
                <div className="alert alert-danger" role="alert">
                  {errorServer}
                </div>
              ) : (
                <div />
              )}
              {showSucces ? (
                <div className="alert alert-success" role="alert">
                  {updateInfoMessage}
                </div>
              ) : (
                <div />
              )}
              <div className="form-group row">
                <div className="form-group col-lg-8" />
                <div className="form-group col-lg-4">
                  <StyledButton
                    type="submit"
                    className={`float-right ${
                      isLoadingButton ? "loading" : ""
                    }`}
                    disabled={isLoadingButton ? true : false}
                    width={buttonWidth}
                    id="update"
                  >
                    {isLoadingButton ? (
                      <i className="fa fa-spinner fa-spin"></i>
                    ) : (
                      MyAccountText.BUTTON_TEXT
                    )}
                  </StyledButton>
                </div>
              </div>
            </form>
          )}

          <hr />
          <Password user={props.user} />
        </div>
      </MyProfilContainer>
    </>
  );
};

export default MyProfil;
