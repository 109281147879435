import styled from 'styled-components';

export const OrderDetails = styled.ul`
    border: 1px solid #e9e9e9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    position: relative;
    margin: 3.7em 0;
    :before, :after{
        content: "";
        display: block;
        position: absolute;
        top: -16px;
        left: 0;
        width: 100%;
        height: 16px;
        background-size: 0px 100%, 16px 16px, 16px 16px;
    }
    @media (max-width: 767px){
        display: block;
    }
`;

export const OrderDetailsItem = styled.li`
    padding: 1em 2em;
    display: flex;
    flex-direction: column;
    vertical-align: middle;
    height: 84px;
    font-size: .8em;
    text-transform: uppercase;
    border-left: 1px solid #e9e9e9;
    strong{
        display: block;
        font-size: 1.41575em;
        text-transform: none;
    }
    @media(max-width: 767px){
        padding: 1em 2em;
        display: block;
        width: 100%;
    }
`

export const OrderContentTitle = styled.h1`
    font-size: 1.786em;
    line-height: 1.6em;
    margin-bottom: 0;
    padding-bottom: 0.4em;
    font-weight: normal;
`

export const OrderTable = styled.table`
    margin-bottom: 2.5em;
    width: 100%;
    max-width: 100%;
`
export const OrderTableTH = styled.th`
    vertical-align: bottom;
    border-bottom: 2px solid #ddd;
    padding: 0.85714em;
    line-height: 1.5;
    border-top: 1px solid #ddd;
`;

export const OrderItemData = styled.td`
   padding: 0.85714em;
    line-height: 1.5;
    vertical-align: top;
    border-top: 1px solid #ddd; 
`;

export const ProductLink  = styled.a`
    color: #2d2b2d;
    text-decoration: none;
    :hover{
        outline: none;
        color: #2d2b2d;
        text-decoration: none;
    }
`;
export const OrderDetailsTH = styled.th`
    padding: 0.85714em;
    line-height: 1.5;
    vertical-align: top;
    border-top: 1px solid #ddd;
`;
export const TableClient = styled.table`
    margin-bottom: 2.5em;
    border: 1px solid #ddd;
    width: 100%;
    max-width: 100%;
`;
export const TableTD = styled.td`
    border: 1px solid #ddd;
    padding: 0.85714em;
    line-height: 1.5;
    vertical-align: top;
`;
export const TableTH = styled.th`
    border: 1px solid #ddd;
    background-color: #f9f9f9;
    padding: 0.85714em;
    line-height: 1.5;
    vertical-align: top;
    font-weight: bold;
    text-align: left;
`;
