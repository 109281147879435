import styled from "styled-components";
const Container = styled.div`
  &.header-absolute {
    position: absolute;
    width: 100%;
  }

  &.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    box-shadow: 0 0 4px ${(props) => props.theme.navBar.boxShaddawStickyNav1},
      0 4px 8px ${(props) => props.theme.navBar.boxShaddawStickyNav2};
    background-color: white;
  }
`;
const ProfileName = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 45px;
  border: 2px solid ${(props) => props.theme.navBar.ProfileName.background}
`
const NavbarContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
`;
const NavbarBrand = styled.div`
  display: flex;
  img {
    height: 70px;
  }
`;
const Menu = styled.ul`
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  li {
  }
  li a {
    font-size: 15px;
    text-decoration: none;
    align-items: center;
    padding: 30px 20px;
    color: ${(props) => props.theme.navBar.Menu.a.color};
    font-weight: 600;
  }
  @media (max-width: 990px) {
    display: none;
  }
  li a:hover {
    color: ${(props) => props.theme.globalStyle.linkHoverColor};
  }
  &.header-absolute {
    li a {
      color: ${(props) => props.theme.navBar.textColor};
    }
    li a:hover {
      color: ${(props) => props.theme.globalStyle.linkHoverColor};
    }
  }
`;
/*
&.header-absolute.sticky{
        li a{
                color:${(props) => props.theme.navBar.Menu.a.color};
        }
    } 
*/

const NavbarToggler = styled.div`
  display: flex;
  ul {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  ul li {
    padding: 30px 15px;
    line-height: 0.9;
  }
  ul li a {
    color: ${(props) => props.theme.navBar.NavbarToggler.a.color};
    cursor: pointer;
    font-size: 24px;
    transition: 0.3s;
    text-decoration: none;
    position: relative;
  }
  & > ul > li > a > span {
    border-radius: 50%;
    box-shadow: 0 3px 12px
      ${(props) => props.theme.navBar.NavbarToggler.boxShaddaw};
    color: ${(props) => props.theme.navBar.textColor};
    font-size: 12px;
    font-weight: 600;
    background-color: ${(props) =>
      props.theme.navBar.NavbarToggler.span.bgColor};
    z-index: 2;
    padding: 4px;
    vertical-align: top;

    position: absolute;
    display: flex;
    justify-content: center;
    top: -4px;
    left: 13px;
    width: 20px;
    height: 20px;
    align-items: center;
  }
  &.header-absolute {
    ul li a {
      color: ${(props) => props.theme.navBar.textColor};
    }
  }
  @media (max-width: 768px) {
    ul li {
      padding: 15px 12px;
    }
  }
`;
const MenuToggler = styled.button`
  display: none;
  flex-direction: column;
  justify-content: space-around;
  height: 24px;
  width: 30px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  box-sizing: border-box;
  &:focus {
    outline: none;
  }
  span {
    margin-bottom: 6px;
    width: 25px;
    height: 1px;
    border-radius: 3px;
    background-color: ${(props) => props.theme.navBar.MenuToggler.span.bgColor};
  }
  span:nth-child(1) {
    margin-right: auto;
    width: 15px;
  }

  span:nth-child(3) {
    margin-bottom: 0;
    margin-left: auto;
    width: 15px;
  }
  @media (max-width: 990px) {
    display: flex;
  }
  &.header-absolute {
    span {
      background-color: ${(props) => props.theme.navBar.bgColor},
};
    }
  }
`;

export {
  NavbarContainer,
  NavbarBrand,
  Menu,
  NavbarToggler,
  MenuToggler,
  Container,
  ProfileName,
};
