import styled from "styled-components";

const ContainerDescription = styled.div`
  padding: 80px 0;
  @media (max-width: 991px) {
    padding: 60px 0;
  }
`;
const ProductThumb = styled.div`
  top: 30px;
  margin-bottom: 30px;
  img {
    max-width: 100%;
    height: auto;
  }
`;
const PriceWrapper = styled.div`
  p {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    margin: 0 0 20px;
  }
`;
const ProductContent = styled.div`
  h1 {
    margin-right: 40px;
    font-size: 42px;
    margin-bottom: 20px;
    line-height: 42px;
    font-weight: 700;
    color: ${(props) => props.theme.description.productContent.titleColor};
    margin-right: 0;
  }
  p {
    margin-bottom: 20px;
    font-size: 15px;
    line-height: 28px;
    color: ${(props) => props.theme.description.productContent.paragraphColor};
  }
`;
const ActionForm = styled.form`
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;
`;
const Icon = styled.i`
  margin-left: 10px;
`;
const FormGroup = styled.div`
  margin-right: 15px;
  margin-bottom: 0;
  label {
    margin-bottom: 10px;
    font-weight: 600;
    color: ${(props) => props.theme.description.formGroup.labelColor};
    text-transform: capitalize;
  }
`;
const Quantity = styled.div`
  display: flex;
  span {
    width: 48px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${(props) => props.theme.description.QuantityBorderColor};
    font-size: 13px;
    background-color: ${(props) => props.theme.description.QuantityBgColor};
  }
  input {
    height: 48px;
    background-color: ${(props) => props.theme.description.QuantityBgColor};
    outline: none;
    width: 50px;
    text-align: center;
    font-weight: 600;
    border: 1px solid ${(props) => props.theme.description.QuantityBorderColor};
  }
  span:first-child {
    border-right: 0;
    border-radius: 25px 0 0 25px;
  }
  span:last-child {
    border-left: 0;
    border-radius: 0 25px 25px 0;
  }
  &.product-option {
    margin-left: 15px;
    span {
      width: 30px;
    }
    input {
      height: 30px;
      width: 32px;
    }
  }
`;
const List = styled.ul`
  list-style: none;
  padding: 0;
  margin-top: 0;
  margin-bottom: 20px;
  li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  li span {
    font-weight: 600;
    color: ${(props) => props.theme.description.list.firstSpan};
    text-transform: capitalize;
    width: 120px;
  }
  li span:last-child {
    color: ${(props) => props.theme.description.list.secondSpan};
    width: 100%;
  }
`;
const RelatedContainer = styled.div`
  padding: 80px 0 50px;
  h3 {
    margin-bottom: 50px;
    font-size: 35px;
    line-height: 43px;
    font-weight: 700;
    color: ${(props) => props.theme.description.relatedProductsTitle};
  }
`;
const ProductContainer = styled.div`
  margin-bottom: 20px;
`;

const OptionBox = styled.div`
  background-color: ${(props) => props.theme.description.optionBoxBgColor};
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 30px;
  height: calc(100% - 30px);
  overflow: hidden;
`;

const OptionTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  h5 {
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 160px;
    white-space: nowrap;
  }
`;
const VariationItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  span {
    font-size: 13px;
    font-weight: 600;
  }
`;
const LabelInput = styled.label`
  font-size: 13px;
  font-weight: 600;
  line-height: 23px;
  color: ${(props) => props.theme.description.labelInput.color};
  input:checked ~ &::before {
    background-color: ${(props) =>
      props.theme.description.labelInput.inputCheckedBgColor};
    color: ${(props) => props.theme.description.labelInput.inputCheckedColor};
  }
  &::before {
    position: absolute;
    top: 8px;
    left: -1px;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    user-select: none;
    background-color: ${(props) =>
      props.theme.description.labelInput.inputCheckedBeforeBgColor};
    transition: background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border-radius: ${(props) => (props.type === "radio" ? "50%" : ".25rem")};
  }

  &::after {
    position: absolute;
    top: 8px;
    left: -1px;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
  }
  &.options {
    &::after {
      top: 3px;
    }
    &::before {
      top: 3px;
    }
  }
  input:checked ~ &::after {
    background-image: ${(props) =>
      props.type === "radio"
        ? `url(
            "data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E"
          )`
        : `url(
            "data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"
          )`};
  }
`;
const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 1rem;
  height: 1rem;
  top: 8px;
  left: -1px;
  z-index: 1;
  &.options {
    top: 3px;
  }
`;

export {
  ContainerDescription,
  ProductThumb,
  ProductContent,
  PriceWrapper,
  ActionForm,
  Icon,
  FormGroup,
  Quantity,
  List,
  RelatedContainer,
  ProductContainer,
  OptionBox,
  OptionTitle,
  VariationItem,
  LabelInput,
  Input,
};
