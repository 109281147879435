/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { GlobalStyle } from "./components/GlobalStyle";
import { ThemeProvider } from "styled-components";
import Footer from "./components/Footer/index";
import theme from "./theme/index";
import HeaderComponent from "./components/Header/index";
import ScrollToTop from "./services/ScrollToTop";
import SubHeader from "./components/SubHeader";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { fetchProducts } from "./store/actions/productsActions";
import { fetchSites } from './store/actions/sitesActions';
import { verify_product_cart } from "./store/actions/cartActions";
import { connect } from "react-redux";
import { routes } from "./constants/routes";
import { addToCart, removeItem } from "./store/actions/cartActions";
import PrivateRoute from "./services/PrivateRoute";
import { getProducts, getTotal, getQuantityTotal } from "./store/Selectors";
import { REACT_APP_GOOGLE_API_KEY, STATIC } from "./constants/env";
import './index.css';
function App(props) {
  const [showSelectStore, setShowSelectStore] = useState(false);
  const [sites, setSites] = useState([]);
  const handleRemoveItem = (item) => {
    props.removeItem(item);
  };
  React.useEffect(() => {
    props.fetchProducts();
    props.fetchSites();
  }, []);
  React.useEffect(() => {
    if (props.sites && props.sites.sites) {
      const currentSite = sessionStorage.getItem('currentSite');
      if (!currentSite && props.sites.sites.length > 0) {
        sessionStorage.setItem('currentSite', JSON.stringify(props.sites.sites[0]));
      }
      if (!currentSite && props.sites.sites.length > 1) {
        setShowSelectStore(true);
      }
      setSites(props.sites.sites);

    }

  }, [props.sites]);
  React.useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${REACT_APP_GOOGLE_API_KEY}&libraries=geometry,places&sensor=false&v=3`;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  React.useEffect(() => {
    const doOperation = async () => {
      let deleted = false;
      let finalProducts = [];
      if (props.products.length > 0) {
        const state = await localStorage.getItem("state");

        const newData = await JSON.parse(state);
        newData.cartReducer.addedItems.forEach((element) => {
          const currentproduct = props.products.find(
            (item) => item._id === element._id
          );
          if (currentproduct === undefined) {
            deleted = true;
          } else {
            const product = {
              _id: currentproduct._id,
              quantity: element.quantity,
              selectedOptions: element.selectedOptions,
              selectedSupplements: element.selectedSupplements,
              instructions: element.instructions,
            };
            finalProducts.push(product);
          }
        });
        if (deleted === true) {
          props.verify_product_cart(finalProducts);
        }
      }
    };
    doOperation();
  }, [props.products]);

  return (
    showSelectStore ?
      <div class="select-container">
       {
         sites && sites.length > 0 ? 
         sites.map(
           site => (
            <div class="site">
            <div class="film"></div>
              <a onClick={(e) => {
                sessionStorage.setItem('currentSite', JSON.stringify(site));
                window.location.reload();
              }} target='_blank' _blank>
                <img src={STATIC.images(site.picture)} alt=""/>
                <div class="site-content">
                  <h1>{site.name}</h1>
                </div>
              </a>
            </div>
           )
         )
         :
         <></>
       }
      </div>

      :
    <Router>
      <ThemeProvider theme={theme}>
        <ScrollToTop />
        
        <GlobalStyle />
        <HeaderComponent
        showSelectStore={setShowSelectStore}
          class={props.showSubHeader ? "header-absolute" : ""}
          home={props.showSubHeader}
          items={props.items}
          total={props.total}
          quantityTotal={props.quantityTotal}
          removeItem={handleRemoveItem}
          isAuthenticated={props.isAuthenticated}
          products={props.products}
        />
        {props.showSubHeader ? (
          <SubHeader title={props.title} bgImage={props.bgImage} menus={props.menus} />
        ) : (
            <></>
          )}

        <Switch>
          {routes.map((elem, index) => {
            return elem.path === "/account" ||
              elem.path === "/login" ||
              elem.path === "/register" ||
              elem.path === "/reset" ||
              elem.path === "/reset-password/:id" ? (
                <PrivateRoute
                  key={index}
                  path={elem.path}
                  isAuthenticated={props.isAuthenticated}
                >
                  <elem.main />
                </PrivateRoute>
              ) : (
                <Route
                  key={index}
                  path={elem.path}
                  exact={elem.exact}
                  children={<elem.main />}
                />
              );
          })}
          <Redirect to="/404" />
        </Switch>
        <Footer showSelectStore={setShowSelectStore}/>
      </ThemeProvider>
    </Router>
  );
}

const mapStateToProps = (state) => {
  return {
    showSubHeader: state.stickyNavbarReducer.subHeader,
    title: state.stickyNavbarReducer.title,
    items: getProducts(state),
    quantityTotal: getQuantityTotal(state),
    total: getTotal(state).toFixed(2),
    isAuthenticated: state.authReducer.isAutentificated,
    bgImage: state.stickyNavbarReducer.bgImage,
    menus: state.stickyNavbarReducer.menus,
    products: state.productsReducer.productsList,
    sites: state.sitesReducer.sitesList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (item) => {
      dispatch(addToCart(item));
    },
    removeItem: (item) => {
      dispatch(removeItem(item));
    },
    fetchProducts: () => {
      dispatch(fetchProducts());
    },
    fetchSites: () => {
      dispatch(fetchSites());
    },
    verify_product_cart: (items) => {
      dispatch(verify_product_cart(items));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
