/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import {
  Container,
  NavbarContainer,
  NavbarBrand,
  Menu,
  NavbarToggler,
  MenuToggler,
  ProfileName,
} from "./style";
import { Link } from "react-router-dom";
import {navbarRoutes} from '../../../constants/routes';
import {images, STATIC} from '../../../constants/env';
const Navbar = (props) => {
  const userLink = props.isAuthenticated ? 'account' : 'login';

  return (
    
    <Container id="myNavbar" className={props.class}>
      <div className="container">
        <NavbarContainer>
          <NavbarBrand>
            <Link to="/">
              <img src={props.storeInformation ? STATIC.images(props.storeInformation.logo) : images.logo} alt="logo" />
            </Link>
          </NavbarBrand>
          <Menu className={props.class}>
            {
              navbarRoutes.map((elem, index )=> (
                <li key={index}>
                <Link to={elem.path}>{elem.name}</Link>
              </li>    
              ))
            }
           
          </Menu>
          <NavbarToggler className={props.class}>
            <ul>
              {props.storeInformation && props.storeInformation.enableCommande ?
              <li onClick={props.cartIconToggle}>
                <a>
                  <i className="flaticon-shopping-bag"></i>
                  <span>{props.quantityTotal}</span>
                </a>
              </li>
              :
              <></>
              }
              <li>
                <Link to={userLink}>
                  {
                    props.user ?
                    <ProfileName className={props.class} src={STATIC.images(props.user.picture)}></ProfileName>
                    :
                    <i className="far fa-user"></i>
                  }
                </Link>
              </li>
              
              <li>
                <MenuToggler onClick={props.drawerToggle} className={props.class}>
                  <span></span>
                  <span></span>
                  <span></span>
                </MenuToggler>
              </li>
            </ul>
          </NavbarToggler>
        </NavbarContainer>
      </div>
    </Container>
  );
};

export default Navbar;
