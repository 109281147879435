import styled from "styled-components";
const CartItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px dashed
    ${(props) => props.theme.cart.cartItem.borderBottomColor};
  padding: 15px 0;

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;
const MediaCartItem = styled.div`
  display: flex;

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  div h5 {
    width: 160px;
    font-weight: 700;
    line-height: 22px;
  }
  div h5 a {
    color: ${(props) => props.theme.cart.cartItem.mediaCartItem.titleColor};
    text-decoration: none;
  }
  div span {
    font-weight: 600;
    font-size: 14px;
    line-height: 28px;
    color: ${(props) =>
      props.theme.cart.cartItem.mediaCartItem.priceQuantityColor};
  }
`;
const DetailsCartItem = styled.div`
  display: flex;
  div {
    font-weight: 700;
    color: ${(props) => props.theme.cart.cartItem.detailsCartItem.color};
    text-align: left;
  }

  @media (max-width: 768px) {
    align-content: flex-end;
    margin-left: auto;
  }
`;

const ImageContainer = styled.div`
  height: 90px;
  width: 90px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 100%;
  margin-right: 15px;
  @media (max-width: 768px) {
    height: 60px;
    width: 60px;
  }
`;
export { CartItemContainer, MediaCartItem, DetailsCartItem, ImageContainer };
