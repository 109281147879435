import styled from "styled-components";
const LoginSection = styled.div`
  padding: 80px 0;
  @media (max-width: 991px) {
    flex-direction: column;
    padding: 60px 0;
  }
`;
const LoginContainer = styled.div`
  display: flex;
  box-shadow: 0 3px 12px -4px ${(props) => props.theme.login.boxShadowColor};

  @media (max-width: 991px) {
    flex-direction: column;
  }
`;
const AuthDescription = styled.div`
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: linear-gradient(
      ${(props) => props.theme.login.bgImageLinearGradient},
      ${(props) => props.theme.login.bgImageLinearGradient}
    ),
    url(${(props) => props.image});
  background-size: cover;
  background-color: transparent;
  width: 600px;
  @media (max-width: 1199px) {
    width: 450px;
  }
  @media (max-width: 991px) {
    width: 100%;
  }
  i {
    line-height: 1;
    font-size: 40px;
    margin-bottom: 30px;
  }
  h2 {
    margin: 0;
    font-size: 40px;
    line-height: 48px;

    color: ${(props) => props.theme.login.textColor};
  }
  p {
    margin: 30px 0;
    font-size: 15px;
    line-height: 28px;
    color: ${(props) => props.theme.login.textColor};
  }
`;
const AuthDescriptionContent = styled.div`
  text-align: center;
  max-width: 400px;
`;
const AuthForm = styled.div`
  flex: 1;
  background-color: ${(props) => props.theme.login.authForm.bgColor};
  padding: 60px;
  text-align: center;
  h2 {
    margin-bottom: 50px;
    font-size: 40px;
    line-height: 48px;
    margin: 0 0 20px;
    font-family: "Oswald", serif;
    font-weight: 700;
    color: ${(props) => props.theme.login.authForm.titleColor};
  }
  a.forget-password {
    display: block;
    color: ${(props) => props.theme.login.authForm.linkColor};
    text-decoration: underline;
  }
  p {
    font-size: 15px;
    line-height: 28px;
    margin-bottom: 15px;
    color: ${(props) => props.theme.login.authForm.paragraphColor};
    margin-top: 20px;
  }
  a.inline-link {
    display: inline;
  }
`;
export {
  LoginContainer,
  AuthDescription,
  AuthForm,
  AuthDescriptionContent,
  LoginSection,
};
