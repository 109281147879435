import {
    FETCH_SITES
} from './action-types/sites-action';
import {API} from '../../constants/env';


export const fetchSites = () => {
   return (dispatch) => {
        fetch(API.settings.sites, {
            method: 'GET',
        })
        .then(res => res.json())
        .then(res => {
            return dispatch({
                type: FETCH_SITES,
                payload: {
                    sites: res,
                },
            });
        })
        .catch(err => console.log(err))
   }  
}