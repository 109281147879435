import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import logger from "redux-logger";
import thunk from "redux-thunk";

import productsReducer from "./reducers/productsReducer";
import cartReducer from "./reducers/cartReducer";
import authReducer from "./reducers/authReducer";
import orderReducer from './reducers/orderReducer';
import sitesReducer from './reducers/sitesReducer';
import stickyNavbarReducer from "./reducers/stickyNavbarReducer";
import { loadState, saveState } from "../services/localStorage";
import throttle from "lodash/throttle";

const persistedState = loadState();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
let middleware = [];
if (process.env.NODE_ENV === "development") {
  middleware = [...middleware, thunk, logger];
} else {
  middleware = [...middleware, thunk];
}

const store = createStore(
  combineReducers({
    cartReducer,
    stickyNavbarReducer,
    authReducer,
    productsReducer,
    orderReducer,
    sitesReducer,
  }),
  persistedState,
  composeEnhancers(applyMiddleware(...middleware))
);

store.subscribe(
  throttle(() => {
    saveState({
      cartReducer: store.getState().cartReducer,
      orderReducer: store.getState().orderReducer,
    });
  }, 1000)
);

export default store;
