import styled from "styled-components";
const SideDrawerNav = styled.nav`
  height: 100vh;
  background: ${(props) => props.theme.sideDrawerNav.nav.bgColor};
  box-shadow: 0 2px 32px ${(props) => props.theme.sideDrawerNav.boxShaddaw};
  position: fixed;
  top: 0;
  left: 0;
  width: 75%;
  z-index: 1200;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  transform: translateX(-100vh);
  transition: transform 0.4s ease-out;
  &.drawer-transtion {
    transform: translateX(0);
  }
  ul li a {
    text-decoration: none;
  }

  a {
    padding: 10px 15px;
    display: block;
    width: 200px;
    margin: 0;
  }
  ul {
    list-style: none;
    padding: 0;
  }
  ul li {
    color: ${(props) => props.theme.sideDrawerNav.list.color};
    font-weight: 700;
    border-bottom: 1px solid
      ${(props) => props.theme.sideDrawerNav.listBorderBottom};
  }
  ul li a {
    color: ${(props) => props.theme.sideDrawerNav.list.color};
    font-weight: 700;
  }

  img {
    height: 75px;
  }
`;
export { SideDrawerNav };
